import {
	HubConnection,
	HubConnectionBuilder,
	LogLevel,
} from '@microsoft/signalr';
import { notification } from 'antd';
import { action, observable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { IMessage } from '../models/messages/Message';
import { RootStore } from './rootStore';

export default class MessangerStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable.ref messangerHubConnection: HubConnection | null = null;
	@observable messages: IMessage[] | undefined;
	@observable temporaryMessageQueue: string[] = [];

	@action addToTemporaryMessageQueue = (messageId: string) => {
		this.temporaryMessageQueue!.push(messageId);
	};

	@action clearTemporaryMessageQueue = () => {
		this.temporaryMessageQueue = [];
	};

	@action getAllMessages = async (electronicDocumentId: number) => {
		try {
			let res = await agent.Messages.getAllMessages(
				electronicDocumentId!
			);
			runInAction(() => {
				this.messages = res;
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action removeMessages = () => {
		runInAction(() => {
			this.messages = undefined;
		});
	};

	@action createMessangerHubConnection = () => {
		this.messangerHubConnection = new HubConnectionBuilder()
			.withUrl(process.env.REACT_APP_MESSAGES_HUB_ADDRESS!, {
				accessTokenFactory: () =>
					this.rootStore.identityStore.accessToken!,
			})
			.configureLogging(LogLevel.None)
			.withAutomaticReconnect()
			.build();

		this.messangerHubConnection!.start().catch((error: any) =>
			console.log(error)
		);

		this.messangerHubConnection!.on('ReceiveMessage', (res) => {
			try {
				runInAction(() => {
					this.messages!.push(res);
				});
			} catch (error) {
				console.log('Error receiving realtime data!');
			}
		});
	};

	@action stopMessangerHubConnection = () => {
		this.messangerHubConnection!.stop();
	};

	@action addMessage = async (message: IMessage) => {
		try {
			await this.messangerHubConnection!.invoke('SendMessage', message);
		} catch (error) {
			console.log(error);
		}
	};

	@action addToMessangerHubGroup = async (electronicDocumentId: number) => {
		await this.messangerHubConnection!.invoke(
			'AddToGroup',
			electronicDocumentId.toString()
		);

		await this.getAllMessages(electronicDocumentId);
	};

	@action removeFromMessangerHubGroup = async (
		electronicDocumentId: number
	) => {
		await this.messangerHubConnection!.invoke(
			'RemoveFromGroup',
			electronicDocumentId.toString()
		);
	};

	@action markAsRead = async (
		isInsuranceCompany: boolean,
		electronicDocumentId: number
	) => {
		await this.messangerHubConnection!.invoke(
			'MarkAsRead',
			isInsuranceCompany,
			electronicDocumentId
		);
	};
}
