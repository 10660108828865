import { Table, Button, Input, Modal, Space, Switch, Tooltip } from 'antd';
import  { TablePaginationConfig } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { IElectronicDocument } from '../../app/models/electronicDocuments/ElectronicDocument';
import { RootStoreContext } from '../../app/stores/rootStore';
import { SyncOutlined, WechatOutlined } from '@ant-design/icons';
import ViewPriorApprovalModal from './ViewPriorApprovalModal';
import Constants from '../../app/constants/constants';
import {
	addResponseMessage,
	addUserMessage,
	dropMessages,
	renderCustomComponent,
	setBadgeCount,
} from 'react-chat-widget';
import CurrentDateChat from '../../app/layout/CustomComponents/CurrentDateChat';
import { DateRangePicker } from 'rsuite';

const ProirApprovalsTable: React.FC<TablePaginationConfig> = ({ total }) => {
	const [show, setShow] = useState(false);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);

	const [filterTable, setFilterTable] = useState([] as IElectronicDocument[]);
	const [rowKey, setRowKey] = useState();
	const [isAllOpen, setIsAllOpen] = useState(false);
	const rootStore = useContext(RootStoreContext);

	const {
		allOpen,
		getAllOpenPriorApprovals,
		getAllPriorApprovals,
		getAllElectronicDocumentsWithUnreadMessages,
		allElectronicDocumentsWithUnreadMessages,
		allPriorApprovals,
		getElectronicDocumentById,
		electronicDocumentById,
		period,
		setPeriod,
		removeAllPriorApprovals,
	} = rootStore.electronicDocumentsStore;

	const {
		getAllMessages,
		removeMessages,
		addToMessangerHubGroup,
		removeFromMessangerHubGroup,
		markAsRead,
		clearTemporaryMessageQueue,
	} = rootStore.messangerStore;

	const {
		removeSelfNotificationForUnreadMessages,
	} = rootStore.notificationStore;

	const { loadingAllTable } = rootStore.loadersStore;

	useEffect(() => {
		if (!allOpen) {
			getAllOpenPriorApprovals(period);
		}
		if (!allPriorApprovals) {
			getAllPriorApprovals(period);
		}

		if (!allElectronicDocumentsWithUnreadMessages) {
			getAllElectronicDocumentsWithUnreadMessages();
		}

		return () => {
			removeAllPriorApprovals();
		};
	}, []);

	const compareByAlph = (a: any, b: any) => {
		if (a > b) {
			return -1;
		}
		if (a < b) {
			return 1;
		}
		return 0;
	};

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([] as IElectronicDocument[]);
		}
	};

	const openModal = async (id: number | undefined) => {
		if (id) {
			await getElectronicDocumentById(id);
			await getAllMessages(id);
			await addToMessangerHubGroup(id);
			rootStore.messangerStore.messages?.map((element) => {
				if (element.isInsuranceCompanyMessage) {
					addUserMessage(element.content);
					renderCustomComponent(CurrentDateChat, {
						date: element.createdOn,
						className: 'rcw-client',
					});
				} else {
					addResponseMessage(element.content);
					renderCustomComponent(CurrentDateChat, {
						date: element.createdOn,
						className: 'rcw-response',
					});
				}

				setBadgeCount(0);
			});
		}
		setShow(true);
	};

	const closeModal = async () => {
		await getAllPriorApprovals(period);
		await getAllOpenPriorApprovals(period);

		setShow(false);

		await markAsRead(
			true,
			rootStore.electronicDocumentsStore.electronicDocumentById!.id
		);
		await removeSelfNotificationForUnreadMessages(
			parseInt(rootStore.identityStore.currentUser!.userId),
			rootStore.electronicDocumentsStore.electronicDocumentById!.id
		);

		dropMessages();
		removeMessages();
		clearTemporaryMessageQueue();

		await removeFromMessangerHubGroup(electronicDocumentById!.id);
	};

	const data = !isAllOpen ? allOpen : allPriorApprovals;

	const changeDatesRange = (dates: Date[]) => {
		setPeriod(dates[0], dates[1]);
		setFilterTable([] as IElectronicDocument[]);
		getAllPriorApprovals(rootStore.electronicDocumentsStore.period);
		getAllOpenPriorApprovals(rootStore.electronicDocumentsStore.period);
	};

	const columns = [
		{
			title: 'Документ №',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			width: '6%',
			align: 'center' as 'center',
		},
		{
			title: 'Приоритет',
			dataIndex: 'isImportant',
			key: 'isImportant',
			width: '4%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.isImportant, b.isImportant);
			},
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: !row.isImportant ? 'inherit' : 'red',
					}}>
					{!row.isImportant ? 'Обикновен' : ' Спешен'}
				</div>
			),
		},
		{
			title: 'Дата на отваряне',
			dataIndex: 'usedOn',
			key: 'usedOn',
			width: '7%',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div>
					<Moment format={Constants.Common.FullDate_Format}>
						{text}
					</Moment>
				</div>
			),
		},
		{
			title: 'Дата на създаване',
			dataIndex: 'createdOn',
			key: 'createdOn',
			width: '7%',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div>
					<Moment format='DD.MM.YYYY HH:mm:ss'>{text}</Moment>
				</div>
			),
		},
		{
			title: 'Лечебно заведение',
			dataIndex: 'medicalFacilityIssuerName',
			key: 'medicalFacilityIssuerName',
			width: '34%',
			align: 'center' as 'center',
		},
		{
			title: 'ЕГН на пациент',
			dataIndex: 'patientUid',
			key: 'patientUid',
			width: '9%',
			sorter: true,
			align: 'center' as 'center',
		},
		{
			title: 'Име на пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			width: '20%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.patientName, b.patientName);
			},
		},
		{
			title: 'Статус',
			dataIndex: 'documentStatus',
			key: 'documentStatus',
			width: '9%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.documentStatus, b.documentStatus);
			},
			render: (text: any, row: any, index: any) => (
				<span
					style={{
						color:
							row.documentStatus ===
							Constants.DocumentProcessStatus.Open
								? 'blue'
								: row.documentStatus ===
								  Constants.DocumentProcessStatus
										.OpenForModification
								? '#FF5722'
								: row.documentStatus ===
								  Constants.DocumentProcessStatus.Approved
								? 'green'
								: row.documentStatus ===
								  Constants.DocumentProcessStatus.Rejected
								? 'red'
								: 'inherit',
					}}>
					{text}
				</span>
			),
		},
		{
			title: '',
			dataIndex: '',
			key: '',
			width: '1%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<span
					style={{
						display:
							rootStore.electronicDocumentsStore
								.allElectronicDocumentsWithUnreadMessages &&
							rootStore.electronicDocumentsStore
								.allElectronicDocumentsWithUnreadMessages!
								.length > 0 &&
							rootStore.electronicDocumentsStore.allElectronicDocumentsWithUnreadMessages!.findIndex(
								(el) => el === row.id
							) > -1
								? 'inherit'
								: 'none',
					}}>
					<Tooltip title={'Имате непрочетени съобщения'}>
						<Button type='link' style={{ padding: 0 }}>
							<WechatOutlined
								style={{ color: '#a03400', fontSize: 25 }}
							/>
						</Button>
					</Tooltip>
				</span>
			),
		},
	];

	const title = (
		<div>
			ИПО № {electronicDocumentById?.documentNumber} от{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{electronicDocumentById?.createdOn}
			</Moment>
		</div>
	);

	return (
		<Fragment>
			<div className='home-grid-col-3'>
				<Space className='space-table-head'>
					<Button
						className='btn-groupe'
						style={{
							background: '#234254',
						}}
						type='primary'
						icon={<SyncOutlined />}
						onClick={async () => {
							if (isAllOpen) {
								await getAllPriorApprovals(period);
							} else {
								await getAllOpenPriorApprovals(period);
							}
						}}>
						Опресняване
					</Button>
					<Switch
						className='switch-in-tables'
						defaultChecked={false}
						onChange={async (value: any) => {
							if (value === true) {
								await getAllOpenPriorApprovals(period);
								setIsAllOpen(true);
							} else {
								await getAllPriorApprovals(period);
								setIsAllOpen(false);
							}
						}}
						checkedChildren='Всички'
						unCheckedChildren='Отворени'></Switch>

					<DateRangePicker
						placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
						cleanable={false}
						className='date-range-tables'
						format={Constants.Common.ShortDate_Format}
						value={[
							rootStore.electronicDocumentsStore.period.dateFrom,
							rootStore.electronicDocumentsStore.period.dateTo,
						]}
						onOk={(date: Date[]) => changeDatesRange(date)}
						locale={Constants.ReactSuiteCalendarLocale}
						size='xs'
						color='green'
						ranges={[]}
						isoWeek={true}
					/>
					<Input.Search
						className='search-input'
						placeholder='Търсене...'
						enterButton
						onSearch={search}
					/>
				</Space>

				<Table
					onRow={(record, index) => {
						return {
							onClick: () => {
								setRowClassName(
									'ant-table-row ant-table-row-level-0 ant-table-row-selected'
								);
								setRowKey(record.id);
							},
							onDoubleClick: async () => {
								await openModal(record.id);
							},
						};
					}}
					loading={loadingAllTable}
					columns={columns}
					dataSource={filterTable.length < 1 ? data : filterTable}
					locale={{ emptyText: 'Няма налични данни' }}
					sortDirections={['descend', 'ascend']}
					showSorterTooltip={false}
					pagination={{
						showSizeChanger: true,
						showQuickJumper: true,
						total: total,
						showTotal: (total) => `Брой резултати: ${total}`,
					}}
					rowClassName={(_record, index) => {
						return _record.id === rowKey ? rowClassName : '';
					}}
					rowKey={rowKey}
				/>

				<Modal
					className='appointment-modal'
					title={title}
					centered
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					onCancel={async () => await closeModal()}
					visible={show}
					footer={false}
					destroyOnClose={true}>
					<ViewPriorApprovalModal
						openModal={openModal}
						closeModal={closeModal}
					/>
				</Modal>
			</div>
		</Fragment>
	);
};
export default observer(ProirApprovalsTable);
