import { ISetUserPassword } from './../models/SetUserPassword';
import { IIdentityUser } from '../models/IdentityUser';
import { observable, action, runInAction, reaction, computed } from 'mobx';
import { history } from '../..';
import agent from '../../api/agent';
import { ISetUserEmail } from '../models/SetUserEmail';
import { notification } from 'antd';
import { RootStore } from './rootStore';

export default class IdentityStore {
	rootStore: RootStore;
	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;

		reaction(
			() => this.refreshToken,
			(token) => {
				if (token) {
					window.sessionStorage.setItem('refresh_token', token);
					this.setUserFromToken(token);
				} else {
					window.sessionStorage.removeItem('refresh_token');
				}
			}
		);

		reaction(
			() => this.accessToken,
			(token) => {
				if (token) {
					window.sessionStorage.setItem('access_token', token);
					this.setUserFromToken(token);
				} else {
					window.sessionStorage.removeItem('access_token');
				}
			}
		);
	}

	@observable accessToken: string | null = window.sessionStorage.getItem(
		'access_token'
	);
	@observable refreshToken: string | null = window.sessionStorage.getItem(
		'refresh_token'
	);

	@observable refreshTokenTimeout: any;
	@observable currentUser: IIdentityUser | undefined;
	@observable loadingLogin = true;
	@observable logoutLoading = false;
	@observable userEmail = '';
	@observable setUserEmail: ISetUserEmail | undefined;
	@observable isUserHavePassword: boolean | undefined;
	@observable setUserPassword: ISetUserPassword | undefined;
	@observable currentSelectedUser: string | undefined;
	@observable loadingInitial = false;

	@computed get isLoggedIn() {
		return !!this.currentUser;
	}

	@action setAccessToken = (token: string | null) => {
		this.accessToken = token;
	};

	@action setRefreshToken = (token: string | null) => {
		this.refreshToken = token;
	};

	@action turnOffLoginLoading = () => {
		this.loadingLogin = false;
	};

	@action b64DecodeUnicode = (str: string) => {
		return decodeURIComponent(
			atob(str)
				.split('')
				.map(function (c) {
					return (
						'%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
					);
				})
				.join('')
		);
	};

	@action logout = async () => {
		this.logoutLoading = true;
		try {
			await agent.IdentityOperations.logout();
			runInAction(() => {
				this.setAccessToken(null);
				this.setRefreshToken(null);
				window.location.assign(process.env.REACT_APP_BLACKBOX_ADDRESS!);
			});
		} catch (error) {
			runInAction(() => {
				this.setAccessToken(null);
				this.setRefreshToken(null);
				window.location.assign(process.env.REACT_APP_BLACKBOX_ADDRESS!);
			});
		}
	};

	@action login = async (key: string) => {
		try {
			var authorizationResponse = await agent.IdentityOperations.authenticate(
				key
			);
			runInAction(() => {
				this.setAccessToken(authorizationResponse.accessToken);
				this.setRefreshToken(authorizationResponse.refreshToken);
				this.startRefreshTokenTimer();
			});
			history.push('/');
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action refresh = async () => {
		this.stopRefreshTokenTimer();
		try {
			let authorizationResponse = await agent.IdentityOperations.refresh(
				this.refreshToken
			);
			runInAction(() => {
				this.setAccessToken(authorizationResponse.accessToken);
				this.setRefreshToken(authorizationResponse.refreshToken);
				this.startRefreshTokenTimer();
			});
		} catch (error) {
			this.setAccessToken(null);
			this.setRefreshToken(null);
			window.location.assign(process.env.REACT_APP_BLACKBOX_ADDRESS!);
		}
	};

	private startRefreshTokenTimer() {
		const jwtToken = JSON.parse(atob(this.accessToken.split('.')[1]));
		const expires = new Date(jwtToken.exp * 1000);
		const timeout = expires.getTime() - Date.now() - 60 * 1000;
		this.refreshTokenTimeout = setTimeout(this.refresh, timeout);
	}

	private stopRefreshTokenTimer() {
		clearTimeout(this.refreshTokenTimeout);
	}

	@action setUserFromToken = async (token: string) => {
		try {
			if (!token) {
				return;
			}
			const base64Url = token.split('.')[1];
			const base64 = base64Url.replace(/-/gi, '+').replace(/_/gi, '/');
			let res = JSON.parse(this.b64DecodeUnicode(base64));
			runInAction(() => {
				this.currentUser = {
					userId: res.nameid,
					userName: res.name,
					friendlyName: res.FriendlyName,
					companyName: res.CompanyName,
					rziCode: res.RziCode,
					medicalFacilityName: res.MedicalFacilityName,
					manager: res.IsManager,
				};
			});
		} catch (error) {
			window.sessionStorage.removeItem('access_token');
			window.sessionStorage.removeItem('refresh_token');
		}
	};

	@action checkUserEmail = async () => {
		this.loadingInitial = true;
		try {
			let res = await agent.IdentityOperations.checkChangeEmailState();
			runInAction(() => {
				this.userEmail = res;
				this.loadingInitial = false;
			});
		} catch (error) {
			this.loadingInitial = false;
		}
	};

	@action setCurrentuserId = async (id: string) => {
		this.currentSelectedUser = id;
	};

	@action updateUserEmail = async (userId: string, newEmail: string) => {
		this.loadingInitial = true;
		try {
			this.setUserEmail = {
				newEmail: newEmail,
			};
			await agent.IdentityOperations.changeEmail(this.setUserEmail);
			runInAction(() => {
				this.loadingInitial = false;
			});
			notification.success({
				message: 'Имейлът е успешно променен!',
				className: 'success-messages',
				duration: 5,
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action checkUserPassword = async (userId: string) => {
		this.loadingInitial = true;
		try {
			let res = await agent.IdentityOperations.checkChangePasswordState();
			runInAction(() => {
				this.isUserHavePassword = res;
				this.loadingInitial = false;
			});
		} catch (error) {
			this.loadingInitial = false;
		}
	};

	@action activateUserPassword = async (
		userId: string,
		password: string,
		repeatPassword: string,
		oldPassword: string
	) => {
		this.loadingInitial = true;
		try {
			this.setUserPassword = {
				password: password,
				repeatPassword: repeatPassword,
				oldPassword: oldPassword,
			};
			await agent.IdentityOperations.changePassword(this.setUserPassword);
			runInAction(() => {
				this.loadingInitial = false;
			});

			if (oldPassword === undefined) {
				notification.success({
					message:
						'Паролата е създадена успешно! Ще бъдете пренасочени към началната страница. Вече имате достъп до системата с ограничен достъп. ',
					className: 'success-messages',
					duration: 10,
				});
				setTimeout(this.logout, 5000);
			} else {
				notification.success({
					message:
						'Паролата е успешно променена! Ще бъдете пренасочени към началната страница. Моля влезте отново в сиситемата! ',
					className: 'success-messages',
					duration: 10,
				});
				setTimeout(this.logout, 5000);
			}
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};
}
