import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { RootStoreContext } from '../../app/stores/rootStore';

const Authenticate: React.FC = () => {
	const queryString = require('query-string');
	const location = useLocation();
	const rootStore = useContext(RootStoreContext);

	const { login, accessToken } = rootStore.identityStore;
	const [authenticationKey, setAuthenticationKey] = useState({ key: '' });

	useEffect(() => {
		setAuthenticationKey(queryString.parse(location.search));
		if (
			authenticationKey.key.length > 0 &&
			(!accessToken || accessToken.length === 0)
		) {
			login(authenticationKey.key);
		}
	}, [
		authenticationKey.key,
		location.search,
		login,
		queryString,
		accessToken,
	]);

	return <LoadingComponent tip='Зареждане на приложението...' />;
};

export default observer(Authenticate);
