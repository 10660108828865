import { notification } from 'antd';
import { action, observable, runInAction } from 'mobx';
import moment from 'moment';
import agent from '../../api/agent';
import { IClaim } from '../models/claims/Claim';
import { IElectronicDocumentEdit } from '../models/claims/ClaimEditSummary';
import { IClaimRequest } from '../models/claims/ClaimRequest';
import { RootStore } from './rootStore';

export default class ClaimStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable allClaims: IClaim[] | undefined;
	@observable claimById: IClaim | undefined;

	@observable period = moment().utc().subtract(1, 'months').toDate();
	@observable requestModel: IClaimRequest | undefined;
	@observable filterType: string;
	@observable isAmbFormTouched = false;
	@observable isPrintingClaimDoc = false;
	@observable isPrintingClaimDetails = false;

	@action setIsPrintingClaimDoc = (val: boolean) => {
		this.isPrintingClaimDoc = val;
	};
	@action setIsPrintingClaimDetails = (val: boolean) => {
		this.isPrintingClaimDetails = val;
	};

	@action setFilterType = (value: string) => {
		this.filterType = value;
	};

	@action setIsAmbFormTouched = (val: boolean) => {
		this.isAmbFormTouched = val;
	};

	@action getClaimsByPeriod = async (period: Date) => {
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = [] as IClaim[];
			if (this.filterType !== undefined && this.filterType.length > 0) {
				switch (this.filterType) {
					case 'all':
						res = await agent.Claim.getAllByPeriod(period);
						break;
					case 'processing':
						res = await agent.Claim.getProcessedByPeriod(period);
						break;
					case 'taken':
						res = await agent.Claim.getTakenByPeriod(period);
						break;
					case 'editable':
						res = await agent.Claim.getForEditingByPeriod(period);
						break;
					case 'performed':
						res = await agent.Claim.getPerformedByPeriod(period);
						break;
				}
			} else {
				res = await agent.Claim.getAllByPeriod(period);
				this.setFilterType('all');
			}
			runInAction(() => {
				this.allClaims = res;
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action getClaimById = async (id: number | undefined) => {
		if (id) {
			this.rootStore.loadersStore.setLoadingAllTables(true);
			try {
				let res = await agent.Claim.byId(id);
				runInAction(() => {
					this.claimById = res;

					this.claimById.details.forEach((detail) => {
						detail.electronicDocuments = [];
						this.claimById?.electronicDocuments.forEach((eDoc) => {
							if (
								eDoc.details.filter(
									(d) =>
										d.systemCode === detail.systemCode &&
										d.price === detail.price
								).length > 0
							) {
								detail.electronicDocuments.push(eDoc);
							}
						});
					});
					this.rootStore.loadersStore.setLoadingAllTables(false);
				});
			} catch (error) {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				runInAction(() => {
					this.rootStore.loadersStore.setLoadingAllTables(false);
				});
			}
		} else {
			this.claimById = undefined;
		}
	};

	@action editElectronicDocument = async (
		electronicDocumentEdit: IElectronicDocumentEdit
	) => {
		try {
			let res = await agent.Claim.editElectronicDocument(
				electronicDocumentEdit
			);
			if (res) {
				notification.success({
					message: 'Редактиран електронен документ',
					className: 'success-messages',
					duration: 5,
				});
			}
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action returnForEditing = async (claimId: number | undefined) => {
		if (claimId) {
			try {
				let res = await agent.Claim.returnForEditing(claimId);
				if (res) {
					notification.success({
						message: 'Искът е върнат за корекция!',
						className: 'success-messages',
						duration: 5,
					});
				}
			} catch (error) {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
			}
		}
	};

	@action perform = async (claimId: number) => {
		try {
			let res = await agent.Claim.perform(claimId);
			if (res) {
				notification.info({
					message: 'Взет за обработка',
					className: 'success-messages',
					duration: 5,
				});
			}
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action setAsProcessed = async (claimId: number) => {
		try {
			let res = await agent.Claim.setAsProcessed(claimId);
			if (res) {
				notification.success({
					message: 'Обработен иск!',
					className: 'success-messages',
					duration: 5,
				});
			}
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@action sortClaims = (
		comparer: (a: any, b: any) => 1 | -1,
		sortDirection: string
	) => {
		if (sortDirection !== 'NONE') {
			let sorted = [...this.allClaims].sort(comparer);
			this.allClaims = sorted;
		}
	};

	@action setPeriod = (date: Date) => {
		this.period = date;
	};

	@action removeAllClaims = () => {
		this.allClaims = undefined;
		this.setPeriod(moment().utc().subtract(1, 'months').toDate());
	};
}
