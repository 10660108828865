import React from 'react';
import PrintSubElDocumentFile from './PrintSubElDocumentFile';

class PrintSubElDocument extends React.Component {
	render() {
		return (
			<div>
				<PrintSubElDocumentFile />
			</div>
		);
	}
}
export default PrintSubElDocument;
