import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';
import Constants from '../../app/constants/constants';

const PrintElectronicDocumentFile: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const { electronicDocumentById } = rootStore.electronicDocumentsStore;
	const { currentUser } = rootStore.identityStore;

	return (
		<div style={{ margin: '10px' }}>
			<h3
				style={{
					textAlign: 'center',
					display:
						electronicDocumentById?.status === 'Запазен'
							? 'none'
							: 'inherit',
				}}>
				Застрахователна компания: {currentUser?.companyName}{' '}
			</h3>
			<br />

			<h3 style={{ textAlign: 'center' }}>
				{' '}
				{electronicDocumentById?.documentType}{' '}
				{electronicDocumentById?.documentNumber} от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicDocumentById?.createdOn}
				</Moment>
				г.
			</h3>

			<br />

			<h4
				style={{
					display: electronicDocumentById?.documentStatus
						? 'inherit'
						: 'none',
				}}>
				Приоритет:{' '}
				{electronicDocumentById?.isImportant === false
					? 'Обикновен'
					: ' Спешен'}
				<br />
			</h4>

			<h4
				style={{
					display: electronicDocumentById?.documentStatus
						? 'inherit'
						: 'none',
				}}>
				Актуален статус: {electronicDocumentById?.documentStatus}
				<br />
			</h4>

			<span
				style={{
					display:
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Approved ||
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Rejected
							? 'inherit'
							: 'none',
				}}>
				<h4> Резолюция: </h4>

				<div className='referral-print-div'>
					{electronicDocumentById?.insuranceCompanyNotes}
				</div>
				<br />
			</span>

			<span
				style={{
					display:
						electronicDocumentById?.documentType.toString() ===
						Constants.DocumentType.PriorApproval
							? 'inherit'
							: 'none',
				}}>
				<h4> Данни за лечебното заведение: </h4>
				<div className='referral-print-div'>
					ЛЗ: {electronicDocumentById?.medicalFacilityIssuerName}
				</div>
				<div className='referral-print-div'>
					Населено място:{' '}
					{electronicDocumentById?.medicalFacilityIssuerCity}
				</div>
				<br />
			</span>
			<span
				style={{
					display: electronicDocumentById?.insuranceCompanyName
						? 'inherit'
						: 'none',
				}}>
				<h4> До застрахователна компания: </h4>
				<div className='referral-print-div'>
					{electronicDocumentById?.insuranceCompanyName}
				</div>
				<br />
			</span>

			<h4> Данни за пациента: </h4>
			<div className='referral-print-div'>
				{' '}
				ЕГН: {electronicDocumentById?.patientUid}{' '}
			</div>
			<div className='referral-print-div'>
				{' '}
				Име: {electronicDocumentById?.patientName}
			</div>
			<br />

			<span
				style={{
					display: electronicDocumentById?.issuerUin
						? 'inherit'
						: 'none',
				}}>
				<h4> Данни за назначилия лекар: </h4>
				<div className='referral-print-div'>
					РЗИ/ЛЗ:{' '}
					{electronicDocumentById?.medicalFacilityIssuerRziCode}/{' '}
					{electronicDocumentById?.medicalFacilityIssuerName}/{' '}
					{electronicDocumentById?.medicalFacilityIssuerCity}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					УИН: {electronicDocumentById?.issuerUin}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					Име: {electronicDocumentById?.issuerName}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					Специалност: {
						electronicDocumentById?.issuerSpecialityName
					}{' '}
				</div>
				<br />
			</span>

			<span
				style={{
					display:
						electronicDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						electronicDocumentById?.performerUin
							? 'inherit'
							: 'none',
				}}>
				<h4>Данни за лекаря, запазил назначението:</h4>
				<div className='referral-print-div'>
					УИН: {electronicDocumentById?.performerUin}
				</div>
				<div className='referral-print-div'>
					Име: {electronicDocumentById?.performerName}
				</div>
				<div className='referral-print-div'>
					Специалност:{' '}
					{electronicDocumentById?.performerSpecialityName}
				</div>
				<br />
			</span>

			<h4> Данни за диагнозата: </h4>
			<div className='referral-print-div'>
				{electronicDocumentById?.mkbCode} -{' '}
				{electronicDocumentById?.mkbName}
			</div>
			<br />

			<h4> Назначени дейности: </h4>
			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 11,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '10%' }}>
							{' '}
							Код по МКБ 9КМ{' '}
						</th>
						<th style={{ border: '1px solid ', width: '40%' }}>
							{' '}
							Дейност{' '}
						</th>
						<th style={{ border: '1px solid ', width: '20%' }}>
							{' '}
							Брой
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							{' '}
							Допълнителна информация{' '}
						</th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{electronicDocumentById?.details.map((detail) => (
						<tr key={uuidv4()}>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.nhifCode}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.name}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.count}{' '}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.description}{' '}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<br />

			<span
				style={{
					display:
						electronicDocumentById?.attachments.length === 0
							? 'none'
							: 'inherit',
				}}>
				<h4> Прикачени файлове: </h4>
				<table
					style={{
						borderCollapse: 'collapse',
						textAlign: 'center',
						width: '100%',
						color: 'black',
						fontSize: 11,
					}}>
					<thead>
						<tr style={{ border: '1px solid ' }}>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Тип на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								{' '}
								Съдържание тип{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								{' '}
								Номер на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Дата на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								Допълнителна информация / Лекар
							</th>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid ' }}>
						{electronicDocumentById?.attachments.map(
							(attachment) => (
								<tr key={uuidv4()}>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.attachmentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.documentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentNumber}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentDate !== null ? (
											<Moment
												format={
													Constants.Common
														.ShortDate_Format
												}>
												{attachment.eDocumentDate}
											</Moment>
										) : (
											''
										)}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.description
											? attachment.description
											: attachment.eDocumentDoctorName}
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
				<br />
			</span>

			<span
				style={{
					display: electronicDocumentById?.medicalNotes
						? 'inherit'
						: 'none',
				}}>
				<h4> Пояснения: </h4>

				<div style={{ border: '1px solid', paddingLeft: 10 }}>
					{electronicDocumentById?.medicalNotes}
				</div>
			</span>
		</div>
	);
};

export default observer(PrintElectronicDocumentFile);
