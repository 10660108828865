import { observer } from 'mobx-react-lite';
import React from 'react';
import ClaimsTable from './ClaimsTable';

const Claims: React.FC = () => {
	return (
		<div>
			<ClaimsTable />
		</div>
	);
};

export default observer(Claims);
