import React from 'react';
import ReactDOM from 'react-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-chat-widget/lib/styles.css';
import 'antd/dist/antd.css';
import './app/layout/styles.css';
import App from './app/layout/App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import 'mobx-react-lite/batchingForReactDom';

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

ReactDOM.render(
	<Router history={history}>
		<App />
	</Router>,
	document.getElementById('root')
);

serviceWorker.unregister();
