import React, { Fragment, useContext } from 'react';
import { Row } from 'antd';
import {
	FileUnknownOutlined,
	UsergroupAddOutlined,
	DesktopOutlined,
	FileDoneOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import HomeGridElement from './HomeGridElement';
import { RootStoreContext } from '../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';

const Home: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { currentUser } = rootStore.identityStore;

	return (
		<Fragment>
			<Row gutter={[32, 24]} justify='center'>
				<HomeGridElement
					link='/priorApproval'
					icon={<FileUnknownOutlined />}
					title='Обработка на ИПО'
					key={uuidv4()}
					style={{}}
				/>
				<HomeGridElement
					link='/claims'
					icon={<FileDoneOutlined />}
					title='Обработка на искове'
					key={uuidv4()}
					style={{}}
				/>
				<HomeGridElement
					link='/accounts'
					icon={<UsergroupAddOutlined />}
					title='Управление на профили'
					key={uuidv4()}
					style={{
						display:
							currentUser?.manager?.length > 0 ? 'block' : 'none',
					}}
				/>
				<HomeGridElement
					link='/helpdesk'
					icon={<DesktopOutlined />}
					title='Помощен център'
					style={{}}
					key={uuidv4()}
				/>
			</Row>
		</Fragment>
	);
};
export default observer(Home);
