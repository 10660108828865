import { Button, Form, Input, notification, Select, Spin, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { v4 as uuidv4 } from 'uuid';
import { IEnumResponse } from '../../app/models/EnumResponse';
import { IHelpdeskRequest } from '../../app/models/helpdesk/HelpdeskRequest';
import constants from '../../app/constants/constants';
import { InfoCircleOutlined } from '@ant-design/icons';

const HelpdeskModal: React.FC = () => {
	const [form] = Form.useForm();
	const rootStore = useContext(RootStoreContext);

	const {
		helpdeskById,
		create,
		setHelpdeskRequest,
		setIsFormTouched,
		helpdeskCategories,
		helpdeskModules,
	} = rootStore.helpdeskStore;
	const { currentUser, userEmail } = rootStore.identityStore;
	const { loadingModal } = rootStore.loadersStore;

	const [categoryState, setCategoryState] = useState(null);

	const { Option } = Select;

	const initialValues = {
		category: helpdeskById ? helpdeskById?.category : undefined,
		module: helpdeskById
			? helpdeskById?.module === 0
				? undefined
				: helpdeskById?.module
			: undefined,
		content: helpdeskById?.content,
		contactEmail: helpdeskById
			? helpdeskById?.contactEmail
			: userEmail
			? userEmail
			: currentUser.userName,
		id: helpdeskById?.id,
	};

	const onCategorySelect = (value: any) => {
		if (value !== 1) {
			form.setFieldsValue({ module: undefined });
		}
		setCategoryState(value);
	};

	const submitForm = async (values) => {
		let helpDeskRequest: IHelpdeskRequest = { ...values };
		let createdResult = await create(helpDeskRequest);
		if (createdResult) {
			setHelpdeskRequest(createdResult);
			notification.success({
				message: 'Заявката е изпратена успешно',
				className: 'success-messages',
				duration: 5,
			});
		}
		setIsFormTouched(false);
	};

	const onFormFieldsChange = () => {
		setIsFormTouched(true);
	};

	return (
		<Spin spinning={loadingModal}>
			<Form
				form={form}
				initialValues={initialValues}
				onFinish={(values) => submitForm(values)}
				onFieldsChange={onFormFieldsChange}>
				<Form.Item>
					<Form.Item
						name='category'
						className='helpdesk-category-input'
						rules={[
							{
								required: true,
								message: constants.FormMessages.RequiredField,
							},
						]}>
						<Select
							placeholder='Избери категория'
							disabled={helpdeskById !== undefined}
							onChange={(value) => onCategorySelect(value)}
							onSelect={(value) => onCategorySelect(value)}>
							{helpdeskCategories?.map(
								(category: IEnumResponse) => (
									<Option
										key={uuidv4()}
										value={category.value}
										title={category.description}>
										{category.description}
									</Option>
								)
							)}
						</Select>
					</Form.Item>
					<Form.Item
						name='module'
						className='helpdesk-module-input'
						rules={[
							{
								required: categoryState === 1 ? true : false,
								message: constants.FormMessages.RequiredField,
							},
						]}>
						<Select
							placeholder='Избери модул'
							disabled={
								helpdeskById !== undefined ||
								categoryState !== 1
							}>
							{helpdeskModules?.map((module: IEnumResponse) => (
								<Option
									key={uuidv4()}
									value={module.value}
									title={module.description}>
									{module.description}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name='contactEmail'
						className='helpdesk-email-input'
						rules={[
							{
								required: true,
								message: constants.FormMessages.RequiredField,
							},
							{
								type: 'email',
								message: 'Моля, попълнете валиден имейл!',
							},
							({ isFieldsTouched }) => ({
								validator(rule, value) {
									if (isFieldsTouched() === false) {
										return Promise.reject();
									}
									return Promise.resolve();
								},
							}),
						]}>
						<Input
							placeholder='Имейл за обратна връзка'
							disabled={helpdeskById !== undefined}
						/>
					</Form.Item>
					<Tooltip title='Този имейл ще бъде използван за обратна връзка, ако желаете можете да го промените.'>
						<InfoCircleOutlined
							style={{
								color: 'red',
								fontSize: '16px',
								verticalAlign: 'middle',
								marginLeft: '6px',
								marginTop: '7px',
							}}
						/>
					</Tooltip>
				</Form.Item>
				<Form.Item
					name='content'
					className='helpdesk-content-input'
					rules={[
						{
							required: true,
							message: constants.FormMessages.RequiredField,
						},
					]}>
					<Input.TextArea
						rows={7}
						maxLength={2000}
						placeholder='Съобщение:'
						disabled={helpdeskById !== undefined}
					/>
				</Form.Item>

				<Form.Item
					name='id'
					style={{
						display:
							helpdeskById !== undefined ? 'none' : 'inherit',
					}}>
					<Button
						key='submit'
						type='primary'
						shape='round'
						className='btn-primary submit-referral-btn'
						htmlType='submit'>
						{' '}
						Изпрати запитване
					</Button>
				</Form.Item>
			</Form>
		</Spin>
	);
};

export default observer(HelpdeskModal);
