import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Constants from '../../constants/constants';

interface IProps {
	isActionRequireMessageTriggered: boolean;
}

const InsuranceCompanyNotesFormItem: React.FC<IProps> = ({
	isActionRequireMessageTriggered,
}) => {
	const rootStore = useContext(RootStoreContext);
	const { electronicDocumentById } = rootStore.electronicDocumentsStore;
	return (
		<Form.Item
			name='insuranceCompanyNotes'
			label='Резолюция / допълнитeлни изисквания:'
			rules={[
				({ isFieldsTouched }) => ({
					validator(rule, value) {
						if (
							isFieldsTouched() === false &&
							isActionRequireMessageTriggered
						) {
							return Promise.reject();
						}
						return Promise.resolve();
					},
				}),
			]}>
			<Input.TextArea
				disabled={
					electronicDocumentById?.documentStatus !==
					Constants.DocumentProcessStatus.Open
				}
			/>
		</Form.Item>
	);
};

export default observer(InsuranceCompanyNotesFormItem);
