import React, { useContext } from 'react';
import { Segment } from 'semantic-ui-react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import PasswordModal from './PasswordModal';
import EmailModal from './EmailModal';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';

const ProfilePage: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const { currentUser } = rootStore.identityStore;

	return (
		<Segment className='profilePage-placeholder'>
			<Avatar size={100} icon={<UserOutlined />} />
			<Title style={{ marginTop: 20 }} level={3}>
				Име: {currentUser?.friendlyName}
			</Title>
			<Title level={3}>Потребителско име: {currentUser?.userName}</Title>
			<PasswordModal />
			<EmailModal />
		</Segment>
	);
};
export default observer(ProfilePage);
