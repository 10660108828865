import React from 'react';
import PrintDocSheet from './PrintDocSheet';

class PrintDocOnClaims extends React.Component {
	render() {
		return (
			<div>
				<PrintDocSheet />
			</div>
		);
	}
}
export default PrintDocOnClaims;
