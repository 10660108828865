import React, { Fragment, useContext } from 'react';
import { Menu } from 'antd';
import {
	UserOutlined,
	LayoutOutlined,
	NotificationOutlined,
	FileUnknownOutlined,
	UsergroupAddOutlined,
	FileDoneOutlined,
} from '@ant-design/icons';
import logo from '../../img/Logo.png';
import { NavLink } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';

const SideBar: React.FC = () => {
	const location = useLocation();
	const rootStore = useContext(RootStoreContext);
	const { currentUser } = rootStore.identityStore;

	return (
		<Fragment>
			<div className='logo-placeholder'>
				<img src={logo} alt='Logo' className='logo' />
			</div>

			<Menu
				mode='inline'
				theme='dark'
				className='sidebar'
				selectedKeys={[location.pathname]}>
				<Menu.Item key='/' icon={<LayoutOutlined />}>
					Начало <NavLink to='/' />
				</Menu.Item>
				<Menu.Item key='/priorApproval' icon={<FileUnknownOutlined />}>
					Обработка на ИПО <NavLink to='/priorApproval' />
				</Menu.Item>
				<Menu.Item key='/claims' icon={<FileDoneOutlined />}>
					Обработка на искове <NavLink to='/claims' />
				</Menu.Item>
				<Menu.Item key='/notifications' icon={<NotificationOutlined />}>
					Известия <NavLink to='/notifications' />
				</Menu.Item>
				<Menu.Item
					style={{
						display:
							currentUser?.manager?.length > 0 ? 'block' : 'none',
					}}
					key='/accounts'
					icon={<UsergroupAddOutlined />}>
					Потребители <NavLink to='/accounts' />
				</Menu.Item>
				<Menu.Item key='/profile' icon={<UserOutlined />}>
					Профил <NavLink to='/profile' />
				</Menu.Item>
			</Menu>
		</Fragment>
	);
};

export default SideBar;
