import { Avatar, Col, Statistic } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface IProps {
	link: string;
	title: ReactNode;
	icon: ReactNode;
	style: CSSProperties;
}

const HomeGridElement: React.FC<IProps> = ({ link, title, icon, style }) => {
	return (
		<Col xs={24} sm={12} md={12} lg={12} xl={6} style={style}>
			<div>
				<Link to={link}>
					<div className='home-grid-col-1'>
						<Avatar
							className='home-grid-avatar'
							size={64}
							icon={icon}
						/>
						<Statistic title={title} value={' '} />
					</div>
				</Link>
			</div>
		</Col>
	);
};
export default observer(HomeGridElement);
