import React, { Fragment, useState, useContext, useEffect } from 'react';

import { Modal, Input, Button, Space, Typography } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import Moment from 'react-moment';

import { Table } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import AccountManagerModalForm from './AccountManagerModalForm';
import { observer } from 'mobx-react-lite';

import { IUser } from '../../app/models/userManagement/User';
import { RootStoreContext } from '../../app/stores/rootStore';

import Constants from '../../app/constants/constants';

const AccountManagerTable: React.FC<TablePaginationConfig> = ({ total }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		getAllUsers,
		allUsers,
		setCurrentSelectedUserId,
		userById,
		getUserById,
		currentSelectedUserId,
		loadingInitial,
	} = rootStore.userManagementStore;

	const [show, setShow] = useState(false);
	const [filterTable, setFilterTable] = useState([] as IUser[]);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [rowKey, setRowKey] = useState(userById?.id);

	const openModal = async (id: number | undefined) => {
		if (id) {
			await getUserById(id);
			setCurrentSelectedUserId(id);
			setShow(true);
		} else {
			await getUserById(undefined);
			setCurrentSelectedUserId(undefined);
			setShow(true);
		}
	};

	const closeModal = async () => {
		setShow(false);
		setCurrentSelectedUserId(undefined);
		await getAllUsers();
	};

	const compareByAlph = (a: any, b: any) => {
		if (a > b) {
			return -1;
		}
		if (a < b) {
			return 1;
		}
		return 0;
	};

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			getAllUsers();
			setFilterTable([] as IUser[]);
		}
	};

	useEffect(() => {
		getAllUsers();
	}, [getAllUsers]);

	const data = allUsers;

	const columns = [
		{
			title: 'Име',
			dataIndex: 'fullName',
			key: 'fullName',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.fullName, b.fullName);
			},
		},
		{
			title: 'Потр. име',
			dataIndex: 'username',
			key: 'username',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.username, b.username);
			},
		},
		{
			title: 'Имейл',
			dataIndex: 'email',
			key: 'email',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div>{text ? text : 'няма'}</div>
			),
		},
		{
			title: 'Статус',
			dataIndex: 'isActive',
			key: 'isActive',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div>
					{!row.isActive ? (
						<span style={{ color: 'red' }}>Неактивен</span>
					) : (
						<span style={{ color: 'green' }}>Активен</span>
					)}
				</div>
			),
		},
		{
			title: 'Последно активен',
			dataIndex: 'lastLogin',
			key: 'lastLogin',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<div>
					{row?.lastLogin ? (
						<Moment format={Constants.Common.ShortDate_Format}>
							{row?.lastLogin}
						</Moment>
					) : (
						<div style={{ color: 'red' }}>Няма</div>
					)}
				</div>
			),
		},
	];

	const { Title } = Typography;

	return (
		<Fragment>
			<div className='home-grid-col-3'>
				<Title level={3}>Управление на профили </Title>
				<Space className='space-table-head'>
					<Button
						className='btn-groupe'
						style={{
							background: '#234254',
						}}
						type='primary'
						icon={<SyncOutlined />}
						onClick={async () => await getAllUsers()}>
						Опресняване
					</Button>
					<Input.Search
						className='search-input'
						placeholder='Търсене...'
						enterButton
						onSearch={search}
					/>
				</Space>
				<Table
					onRow={(record, index) => {
						return {
							onClick: () => {
								setRowClassName(
									'ant-table-row ant-table-row-level-0 ant-table-row-selected'
								);
								setRowKey(record.id);
							},
							onDoubleClick: async () => {
								await openModal(record.id);
							},
						};
					}}
					loading={loadingInitial}
					columns={columns}
					dataSource={filterTable.length < 1 ? data : filterTable}
					locale={{ emptyText: 'Няма налични данни',  }}
					sortDirections={['descend', 'ascend']}
					showSorterTooltip={false}
					pagination={{
						showSizeChanger: true,
						showQuickJumper: true,
						total: total,
						showTotal: (total) => `Брой резултати: ${total}`,
					}}
					rowClassName={(_record, index) => {
						return _record.id === rowKey ? rowClassName : '';
					}}
					rowKey={rowKey?.toString()}
				/>
				<Modal
					confirmLoading={loadingInitial}
					key={userById?.id ? currentSelectedUserId : undefined!}
					width='600px'
					title='Редактиране'
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					centered
					onCancel={async () => await closeModal()}
					visible={show}
					footer={false}
					destroyOnClose={true}>
					<AccountManagerModalForm closeModal={closeModal} />
				</Modal>
			</div>
		</Fragment>
	);
};

export default observer(AccountManagerTable);
