import React, { useContext } from 'react';
import { RouteProps, RouteComponentProps, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../stores/rootStore';

interface IProps extends RouteProps {
	component: React.ComponentType<RouteComponentProps<any>>;
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
	const rootStore = useContext(RootStoreContext);
	const { isLoggedIn } = rootStore.identityStore;
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isLoggedIn) {
					return <Component {...props} />;
				} else {
					window.location.assign(
						process.env.REACT_APP_BLACKBOX_ADDRESS!
					);
				}
			}}
		/>
	);
};

export default observer(PrivateRoute);
