import { IUpdateUser } from './../app/models/userManagement/UpdateUser';
import { IUser } from './../app/models/userManagement/User';
import axios, { AxiosResponse } from 'axios';
import { history } from '..';
import { ISetUserPassword } from '../app/models/SetUserPassword';
import { ISetUserEmail } from '../app/models/SetUserEmail';
import { IElectronicDocument } from '../app/models/electronicDocuments/ElectronicDocument';
import { IDownloadAttachment } from '../app/models/electronicDocuments/DownloadAttachment';
import { IStatisticsInformation } from '../app/models/electronicDocuments/StatisticsInformation';
import { IMessage } from '../app/models/messages/Message';
import { INotification } from '../app/models/notifications/Notification';
import { IClaim } from '../app/models/claims/Claim';
import { IByPeriodSearch } from '../app/models/electronicDocuments/ByPeriodSearch';
import { IElectronicDocumentEdit } from '../app/models/claims/ClaimEditSummary';
import { IAuthorizationResponse } from '../app/models/AuthorizationResponse';
import { IHelpdeskRequest } from '../app/models/helpdesk/HelpdeskRequest';
import { IEnumResponse } from '../app/models/EnumResponse';

const mainAxios = axios.create({
	baseURL: process.env.REACT_APP_API_ADDRESS!,
});

const messagesAxios = axios.create({
	baseURL: process.env.REACT_APP_MESSANGER_API_ADDRESS!,
});

axios.defaults.baseURL = process.env.REACT_APP_API_ADDRESS;

mainAxios.interceptors.request.use(
	(config) => {
		const token = window.sessionStorage.getItem('access_token');
		if (token) config.headers.Authorization = `Bearer ${token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

messagesAxios.interceptors.request.use(
	(config) => {
		const token = window.sessionStorage.getItem('access_token');
		if (token) config.headers.Authorization = `Bearer ${token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

mainAxios.interceptors.response.use(undefined, (error) => {
	if (error.message === 'Network Error' && !error.response) {
		// message.error('Network error - make sure API is running!');
		console.log('Network error - make sure API is running!');
	}
	const { status } = error.response;
	if (status === 404) {
		history.push('/');
	}
	if (status === 401) {
		// && headers['www-authenticate'] === 'Bearer error="invalid_token", error_description="The token is expired"'
		window.sessionStorage.removeItem('access_token');
		window.sessionStorage.removeItem('refresh');
		window.location.assign(process.env.REACT_APP_BLACKBOX_ADDRESS!);
	}
	if (status === 403) {
		window.sessionStorage.removeItem('access_token');
		window.location.assign(process.env.REACT_APP_BLACKBOX_ADDRESS!);
	}

	throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
	get: (url: string) => mainAxios.get(url).then(responseBody),
	post: (url: string, body: {}) =>
		mainAxios.post(url, body).then(responseBody),
	postWithBlobResponse: (url: string, body: {}) =>
		mainAxios.post(url, body, { responseType: 'blob' }).then(responseBody),
	postWithoutBody: (url: string) => mainAxios.post(url).then(responseBody),
	put: (url: string, body: {}) => mainAxios.put(url, body).then(responseBody),
	putWithoutBody: (url: string) => mainAxios.put(url).then(responseBody),
	del: (url: string, body: {}) =>
		mainAxios.delete(url, body).then(responseBody),
};

const messagesRequests = {
	get: (url: string) => messagesAxios.get(url).then(responseBody),
	post: (url: string, body: {}) =>
		messagesAxios.post(url, body).then(responseBody),
};

const IdentityOperations = {
	authenticate: (key: string): Promise<IAuthorizationResponse> =>
		requests.post('/auth/login', { key: key }),
	refresh: (refreshToken: string): Promise<IAuthorizationResponse> =>
		requests.post('/auth/refresh', { refreshToken: refreshToken }),
	logout: (): Promise<void> => requests.post('/auth/logout', {}),
	checkChangePasswordState: (): Promise<boolean> =>
		requests.postWithoutBody('/auth/changepassword'),
	changePassword: (setUserPasswordModel: ISetUserPassword): Promise<number> =>
		requests.put('/auth/changepassword', setUserPasswordModel),
	checkChangeEmailState: (): Promise<string> =>
		requests.postWithoutBody('/auth/changeemail'),
	changeEmail: (setUserEmailModel: ISetUserEmail): Promise<number> =>
		requests.put('/auth/changeemail', setUserEmailModel),
};

const UserManagement = {
	getAll: (): Promise<IUser[]> =>
		requests.postWithoutBody('/UserManagement/All'),
	getById: (userId: number) =>
		requests.post('/UserManagement/GetById', { id: userId }),
	update: (user: IUpdateUser): Promise<boolean> =>
		requests.put('/UserManagement/Update', user),
	delete: (userId: number): Promise<boolean> =>
		requests.post('/UserManagement/Delete', { id: userId }),
	activate: (userId: number) =>
		requests.post('/UserManagement/ActivateUser', { id: userId }),
	deactivate: (userId: number) =>
		requests.post('/UserManagement/DeactivateUser', { id: userId }),
};

const ElectronicDocuments = {
	getById: (id: number) =>
		requests.post('/ElectronicDocuments/ById', { id: id }),

	downloadAttachment: (attachment: IDownloadAttachment): Promise<any> =>
		requests.postWithBlobResponse(
			'/ElectronicDocuments/DownloadAttachment',
			attachment
		),
	downloadClaimDetailsToExcel: (claimId: number): Promise<any> =>
		requests.postWithBlobResponse(
			'/ElectronicDocuments/DownloadClaimDetailsToExcel',
			{ id: claimId }
		),

	downloadClaimAttachmentsToExcel: (claimId: number): Promise<any> =>
		requests.postWithBlobResponse(
			'/ElectronicDocuments/DownloadClaimAttachmentsToExcel',
			{ id: claimId }
		),
};

const PriorApproval = {
	getStatisticsInformation: (): Promise<IStatisticsInformation> =>
		requests.get('/PriorApproval/GetStatisticsInformation'),

	getNextPending: (): Promise<IElectronicDocument> =>
		requests.get('/PriorApproval/GetNextPending'),

	getAllOpen: (period: IByPeriodSearch): Promise<IElectronicDocument[]> =>
		requests.post('/PriorApproval/GetAllOpenByPeriod', period),

	getAll: (period: IByPeriodSearch): Promise<IElectronicDocument[]> =>
		requests.post('/PriorApproval/GetAllByPeriod', period),

	requestChanges: (id: number): Promise<boolean> =>
		requests.post('/PriorApproval/RequestChanges', { id: id }),

	getBackInOpenMode: (id: number): Promise<boolean> =>
		requests.post('/PriorApproval/GetBackInOpenMode', { id: id }),

	approve: (id: number): Promise<boolean> =>
		requests.post('/PriorApproval/Approve', {
			id: id,
		}),

	reject: (id: number, message: string): Promise<boolean> =>
		requests.post('/PriorApproval/Reject', {
			id: id,
			message: message,
		}),
};

const Messages = {
	getAllMessages: (id: number): Promise<IMessage[]> =>
		messagesRequests.post('/MessageApi/GetAllMessages', { id: id }),
};

const Notifications = {
	GetAllNotifications: (): Promise<INotification[]> =>
		messagesRequests.get('/NotificationApi/GetAllNotifications'),

	GetAllNotificationsForCompany: (
		companyName: string
	): Promise<INotification[]> =>
		messagesRequests.post('/NotificationApi/GetAllNotificationForCompany', {
			companyName: companyName,
		}),

	GetNotReadNotifications: (): Promise<INotification[]> =>
		messagesRequests.get('/NotificationApi/GetNotReadNotifications'),

	GetNotReadNotificationsForCompany: (
		companyName: string
	): Promise<INotification[]> =>
		messagesRequests.post(
			'/NotificationApi/GetNotReadNotificationsForCompany',
			{ companyName: companyName }
		),

	GetAllElectronicDocumentsWithUnreadNotifications: (): Promise<number[]> =>
		messagesRequests.get(
			'/NotificationApi/GetAllElectronicDocumentsWithUnreadNotifications'
		),
};

const Claim = {
	getAllByPeriod: (period: Date): Promise<IClaim[]> =>
		requests.post('/Claims/GetAllByPeriod', { date: period }),

	getProcessedByPeriod: (period: Date): Promise<IClaim[]> =>
		requests.post('/Claims/GetProcessedByPeriod', { date: period }),

	getTakenByPeriod: (period: Date): Promise<IClaim[]> =>
		requests.post('/Claims/GetTakenByPeriod', { date: period }),

	getForEditingByPeriod: (period: Date): Promise<IClaim[]> =>
		requests.post('/Claims/GetForEditingByPeriod', { date: period }),

	getPerformedByPeriod: (period: Date): Promise<IClaim[]> =>
		requests.post('/Claims/GetPerformedByPeriod', { date: period }),

	byId: (id: number): Promise<IClaim> =>
		requests.post('/Claims/GetById', { id: id }),

	perform: (id: number): Promise<boolean> =>
		requests.put('/Claims/Perform', { id: id }),

	setAsProcessed: (id: number): Promise<boolean> =>
		requests.put('/Claims/SetAsProcessed', { id: id }),

	returnForEditing: (id: number): Promise<boolean> =>
		requests.put('/Claims/ReturnForEditing', { id: id }),

	editElectronicDocument: (
		editRequest: IElectronicDocumentEdit
	): Promise<boolean> =>
		requests.put('/Claims/EditElectronicDocument', editRequest),
};

const Helpdesk = {
	getAllByPeriod: (period: IByPeriodSearch): Promise<IHelpdeskRequest[]> =>
		requests.post('/Helpdesk/GetAllByPeriod', period),
	byId: (id: number): Promise<IHelpdeskRequest> =>
		requests.post('/Helpdesk/GetById', { id: id }),
	create: (request: IHelpdeskRequest): Promise<IHelpdeskRequest> =>
		requests.post('/Helpdesk/Create', request),
	getCategories: (): Promise<IEnumResponse[]> =>
		requests.get('/Helpdesk/GetCategories'),
	getModules: (): Promise<IEnumResponse[]> =>
		requests.get('/Helpdesk/GetModules'),
};

export default {
	IdentityOperations,
	UserManagement,
	ElectronicDocuments,
	PriorApproval,
	Messages,
	Notifications,
	Claim,
	Helpdesk,
};
