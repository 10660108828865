import React from 'react';

import PrintElectronicDocumentFile from './PrintElectronicDocumentFile';

class PrintElectronicDocument extends React.Component {
	render() {
		return (
			<div>
				<PrintElectronicDocumentFile />
			</div>
		);
	}
}
export default PrintElectronicDocument;
