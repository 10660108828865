import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import SideBar from '../../features/sidebarNavigation/SideBar';
import NavBar from '../../features/navigation/NavBar';
import Home from '../../features/homeGrid/Home';
import { ConfigProvider, Layout } from 'antd';
import 'antd-mobile/dist/antd-mobile.css';
import DrawerBar from '../../features/mobile/Drawer/DrawerBar';
import ProfilePage from '../../features/profilePage/ProfilePage';
import AccountsManager from '../../features/accountsManager/AccountsManager';
import Authenticate from '../../features/authentication/Authenticate';
import { observer } from 'mobx-react-lite';
import LoadingComponent from './LoadingComponent';
import PrivateRoute from './PrivateRoute';
import PriorApproval from '../../features/PriorApproval/PriorApproval';
import bg_BG from 'antd/es/locale/bg_BG';
import 'moment/locale/bg';
import { RootStoreContext } from '../stores/rootStore';
import Notifications from '../../features/notificationsPage/Notifications';
import Claims from '../../features/claims/Claims';
import HelpdeskMain from '../../features/helpdesk/HelpdeskMain';

const { Header, Sider, Content } = Layout;

const App: React.FC<RouteComponentProps> = ({ history, location }) => {
	const [collapsed, setCollapsed] = useState(true);
	const [isMobile, setIsMobile] = useState(false);

	const rootStore = useContext(RootStoreContext);
	const {
		accessToken,
		loadingLogin,
		logoutLoading,
		setUserFromToken,
		turnOffLoginLoading,
		currentUser,
		refreshToken,
		refresh,
	} = rootStore.identityStore;

	const handleWindowResize = () => {
		setIsMobile(window.innerWidth < 770);
	};

	const onCollapse = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		async function initialization() {
			if (accessToken) {
				await setUserFromToken(accessToken);
				await rootStore.setBaseConfiguration();
			}

			if (
				refreshToken &&
				currentUser === undefined &&
				process.env.REACT_APP_NODE_ENV !== 'development'
			) {
				await refresh();
			}

			turnOffLoginLoading();
		}

		initialization();
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
	}, [
		accessToken,
		refresh,
		refreshToken,
		turnOffLoginLoading,
		setUserFromToken,
	]);

	if (loadingLogin)
		return <LoadingComponent tip='Зареждане на приложението...' />;
	if (logoutLoading) return <LoadingComponent tip='Изход' />;

	return (
		<ConfigProvider locale={bg_BG}>
			<Fragment>
				{!isMobile && accessToken ? (
					<Route
						path={'/' || '(.+)'}
						render={() => (
							<Layout style={{ minHeight: '100vh' }}>
								<Sider
									collapsible
									collapsed={collapsed}
									onCollapse={onCollapse}
									width='250px'
									collapsedWidth='60px'>
									<PrivateRoute
										path='/'
										component={SideBar}
									/>
								</Sider>
								<Layout className='site-layout'>
									<Header
										className='site-layout-background'
										style={{ padding: 0 }}>
										<PrivateRoute
											path='/'
											component={NavBar}
										/>
									</Header>

									<Content style={{ margin: '0 5px' }}>
										<PrivateRoute
											exact
											path='/'
											component={Home}
										/>
										<Route
											path='/authenticate'
											component={Authenticate}
										/>
										{/* <PrivateRoute
											path='/accounts'
											component={AccountsManager}
										/> */}
										{currentUser?.manager?.length > 0 ? (
											<PrivateRoute
												path='/accounts'
												component={AccountsManager}
											/>
										) : (
											<Route path='/notFound' />
										)}
										<PrivateRoute
											path='/profile'
											component={ProfilePage}
										/>
										<PrivateRoute
											exact
											path='/priorApproval'
											component={PriorApproval}
										/>
										<PrivateRoute
											exact
											path='/claims'
											component={Claims}
										/>
										<PrivateRoute
											path='/helpdesk'
											component={HelpdeskMain}
										/>
										<PrivateRoute
											exact
											path='/notifications'
											component={Notifications}
										/>
									</Content>
								</Layout>
							</Layout>
						)}
					/>
				) : (
					<Route
						path={'/' || '(.+)'}
						render={() => (
							<Layout style={{ minHeight: '100vh' }}>
								<DrawerBar />
								{accessToken ? (
									<Layout className='site-layout'>
										<Content style={{ margin: '0 16px' }}>
											<PrivateRoute
												exact
												path='/'
												component={Home}
											/>

											{currentUser?.manager?.length >
											0 ? (
												<PrivateRoute
													path='/accounts'
													component={AccountsManager}
												/>
											) : (
												<Route path='/notFound' />
											)}
											<PrivateRoute
												path='/profile'
												component={ProfilePage}
											/>
											<PrivateRoute
												exact
												path='/priorApproval'
												component={PriorApproval}
											/>
											<PrivateRoute
												exact
												path='/claims'
												component={Claims}
											/>
											<PrivateRoute
												path='/helpdesk'
												component={HelpdeskMain}
											/>
											<PrivateRoute
												exact
												path='/notifications'
												component={Notifications}
											/>
										</Content>
									</Layout>
								) : (
									<Layout
										className='site-layout'
										style={{ display: 'none' }}>
										<Content style={{ margin: '0 16px' }}>
											<PrivateRoute
												exact
												path='/'
												component={Home}
											/>
										</Content>
									</Layout>
								)}
								<Route
									path='/authenticate'
									component={Authenticate}
								/>
							</Layout>
						)}
					/>
				)}
			</Fragment>
		</ConfigProvider>
	);
};

export default withRouter(observer(App));
