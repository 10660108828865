import { observer } from 'mobx-react-lite';
import React from 'react';
import HelpdeskGrid from './HelpdeskGrid';

const HelpdeskMain: React.FC = () => {
	return (
		<div>
			<HelpdeskGrid />
		</div>
	);
};

export default observer(HelpdeskMain);
