import { Button, Modal, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useState } from 'react';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import ViewPriorApprovalModal from './ViewPriorApprovalModal';
import Constants from '../../app/constants/constants';
import {
	addUserMessage,
	renderCustomComponent,
	addResponseMessage,
	setBadgeCount,
	dropMessages,
} from 'react-chat-widget';
import CurrentDateChat from '../../app/layout/CustomComponents/CurrentDateChat';

const PriorApprovalNextPendingButton = () => {
	const rootStore = useContext(RootStoreContext);

	const [show, setShow] = useState(false);

	const {
		getAllOpenPriorApprovals,
		getAllPriorApprovals,
		getElectronicDocumentById,
		electronicDocumentById,
		getNextPendingPriorApproval,
		period,
	} = rootStore.electronicDocumentsStore;

	const {
		getAllMessages,
		removeMessages,
		addToMessangerHubGroup,
		removeFromMessangerHubGroup,
		markAsRead,
		clearTemporaryMessageQueue,
	} = rootStore.messangerStore;

	const {
		removeSelfNotificationForUnreadMessages,
	} = rootStore.notificationStore;
	const { loadingButtons } = rootStore.loadersStore;

	const getNextPending = async () => {
		await getNextPendingPriorApproval();
		if (!rootStore.electronicDocumentsStore.electronicDocumentById) {
			notification.success({
				message: 'Няма нови записи!',
				duration: 5,
				className: 'success-messages',
			});
		} else {
			await openModal(
				rootStore.electronicDocumentsStore.electronicDocumentById!.id
			);
		}
	};

	const openModal = async (id: number | undefined) => {
		if (id) {
			await getElectronicDocumentById(id);
			await getAllMessages(id);
			await addToMessangerHubGroup(id);
			rootStore.messangerStore.messages?.map((element) => {
				if (element.isInsuranceCompanyMessage) {
					addUserMessage(element.content);
					renderCustomComponent(CurrentDateChat, {
						date: element.createdOn,
						className: 'rcw-client',
					});
				} else {
					addResponseMessage(element.content);
					renderCustomComponent(CurrentDateChat, {
						date: element.createdOn,
						className: 'rcw-response',
					});
				}

				setBadgeCount(0);
			});
		}
		setShow(true);
	};

	const closeModal = async () => {
		await getAllPriorApprovals(period);

		await getAllOpenPriorApprovals(period);

		setShow(false);

		await markAsRead(
			true,
			rootStore.electronicDocumentsStore.electronicDocumentById!.id
		);
		await removeSelfNotificationForUnreadMessages(
			parseInt(rootStore.identityStore.currentUser!.userId),
			rootStore.electronicDocumentsStore.electronicDocumentById!.id
		);

		dropMessages();
		removeMessages();
		clearTemporaryMessageQueue();

		await removeFromMessangerHubGroup(electronicDocumentById!.id);
	};

	const title = (
		<div>
			ИПО № {electronicDocumentById?.documentNumber} от{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{electronicDocumentById?.createdOn}
			</Moment>
		</div>
	);

	return (
		<Fragment>
			<Button
				loading={loadingButtons}
				onClick={async () => await getNextPending()}
				className='btn-primary'
				style={{ margin: '0px' }}
				type='primary'>
				Обработка на следващ документ
			</Button>
			<Modal
				className='appointment-modal'
				title={title}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={async () => await closeModal()}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				<ViewPriorApprovalModal
					openModal={openModal}
					closeModal={closeModal}
				/>
			</Modal>
		</Fragment>
	);
};
export default observer(PriorApprovalNextPendingButton);
