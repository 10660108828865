import { RootStore } from './rootStore';
import { action, observable, runInAction } from 'mobx';
import agent from '../../api/agent';
import { IElectronicDocument } from '../models/electronicDocuments/ElectronicDocument';
import { IDownloadAttachment } from '../models/electronicDocuments/DownloadAttachment';
import { IStatisticsInformation } from '../models/electronicDocuments/StatisticsInformation';
import { notification } from 'antd';
import { IByPeriodSearch } from '../models/electronicDocuments/ByPeriodSearch';
import moment from 'moment';

export default class ElectronicDocumentsStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable statisticsInformation: IStatisticsInformation | undefined;
	@observable nextPending: IElectronicDocument | undefined;
	@observable allOpen: IElectronicDocument[] | undefined;
	@observable allPriorApprovals: IElectronicDocument[] | undefined;
	@observable electronicDocumentById: IElectronicDocument | undefined;
	@observable electronicSubDocumentById: IElectronicDocument | undefined;

	@observable attachmentDownloadResult: any;
	@observable claimDetailsExcelDownloadResult: any;
	@observable claimAttachmentsExcelDownloadResult: any;

	@observable allElectronicDocumentsWithUnreadMessages: number[] | undefined;

	@observable period: IByPeriodSearch = {
		dateFrom: moment().subtract(1, 'month').startOf('month').utc().toDate(),
		dateTo: moment().utc().toDate(),
	};

	@action setPeriod = (dateFrom: Date, dateTo: Date) => {
		this.period.dateFrom = dateFrom;
		this.period.dateTo = dateTo;
	};

	@action getStatisticsInformation = async () => {
		try {
			let res = await agent.PriorApproval.getStatisticsInformation();
			runInAction(() => {
				this.statisticsInformation = res;
			});
		} catch (error) {
			notification.error({
				message: error.data,
				className: 'success-messages',
				duration: 5,
			});
		}
	};

	@observable getAllElectronicDocumentsWithUnreadMessages = async () => {
		try {
			let res = await agent.Notifications.GetAllElectronicDocumentsWithUnreadNotifications();
			runInAction(() => {
				this.allElectronicDocumentsWithUnreadMessages = res;
			});
		} catch (error) {}
	};

	@action getNextPendingPriorApproval = async () => {
		this.rootStore.loadersStore.setLoadingButtons(true);
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.PriorApproval.getNextPending();
			runInAction(() => {
				this.electronicDocumentById = res;
				this.rootStore.loadersStore.setLoadingButtons(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingButtons(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action getAllOpenPriorApprovals = async (period: IByPeriodSearch) => {
		this.rootStore.loadersStore.setLoadingButtons(true);
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.PriorApproval.getAllOpen(period);
			runInAction(() => {
				this.allOpen = res;
				this.rootStore.loadersStore.setLoadingButtons(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingButtons(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action getAllPriorApprovals = async (period: IByPeriodSearch) => {
		this.rootStore.loadersStore.setLoadingButtons(true);
		this.rootStore.loadersStore.setLoadingAllTables(true);
		try {
			let res = await agent.PriorApproval.getAll(period);
			runInAction(() => {
				this.allPriorApprovals = res;
				this.rootStore.loadersStore.setLoadingButtons(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingButtons(false);
				this.rootStore.loadersStore.setLoadingAllTables(false);
			});
		}
	};

	@action requestChanges = async (id: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.PriorApproval.requestChanges(id);
			if (res) {
				notification.success({
					message: 'Изпратена е заявка за промяна на ИПО.',
					className: 'success-messages',
					duration: 5,
				});
			}
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		}
	};

	@action getBackInOpenMode = async (id: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.PriorApproval.getBackInOpenMode(id);
			if (res) {
				notification.success({
					message: 'Изпратена е заявка за промяна на ИПО.',
					className: 'success-messages',
					duration: 5,
				});
			}
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		}
	};

	@action approve = async (id: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.PriorApproval.approve(id);
			if (res) {
				notification.success({
					message: 'Заявката е изпълнена!',
					className: 'success-messages',
					duration: 5,
				});
			}
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		}
	};

	@action reject = async (id: number, message: string) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.PriorApproval.reject(id, message);
			if (res) {
				notification.success({
					message: 'Заявката е отказана!',
					className: 'success-messages',
					duration: 5,
				});
			}
			runInAction(() => {
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			runInAction(() => {
				notification.error({
					message: error.data,
					className: 'success-messages',
					duration: 5,
				});
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		}
	};

	@action getElectronicDocumentById = async (id: number | undefined) => {
		if (id) {
			this.rootStore.loadersStore.setLoadingButtons(true);
			this.rootStore.loadersStore.setLoadingAllTables(true);
			try {
				let res = await agent.ElectronicDocuments.getById(id);
				runInAction(() => {
					this.electronicDocumentById = res;
					this.rootStore.loadersStore.setLoadingButtons(false);
					this.rootStore.loadersStore.setLoadingAllTables(false);
				});
			} catch (error) {
				runInAction(() => {
					notification.error({
						message: error.data,
						className: 'success-messages',
						duration: 5,
					});
					this.rootStore.loadersStore.setLoadingButtons(false);
					this.rootStore.loadersStore.setLoadingAllTables(false);
				});
			}
		} else {
			this.electronicDocumentById = undefined;
		}
	};

	@action getElectronicSubDocumentById = async (id: number | undefined) => {
		if (id) {
			this.rootStore.loadersStore.setLoadingModal(true);
			try {
				let res = await agent.ElectronicDocuments.getById(id);
				runInAction(() => {
					this.electronicSubDocumentById = res;
					this.rootStore.loadersStore.setLoadingModal(false);
				});
			} catch (error) {
				runInAction(() => {
					notification.error({
						message: error.data,
						className: 'success-messages',
						duration: 5,
					});
					this.rootStore.loadersStore.setLoadingModal(false);
				});
			}
		} else {
			this.electronicDocumentById = undefined;
		}
	};

	@action downloadAttachment = async (attachment: IDownloadAttachment) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.ElectronicDocuments.downloadAttachment(
				attachment
			);
			runInAction(() => {
				this.attachmentDownloadResult = res;
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action exportClaimDetailsToExcel = async (claimId: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.ElectronicDocuments.downloadClaimDetailsToExcel(
				claimId
			);
			runInAction(() => {
				this.claimDetailsExcelDownloadResult = res;
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action exportClaimAttachmentsToExcel = async (claimId: number) => {
		this.rootStore.loadersStore.setLoadingModal(true);
		try {
			let res = await agent.ElectronicDocuments.downloadClaimAttachmentsToExcel(
				claimId
			);
			runInAction(() => {
				this.claimAttachmentsExcelDownloadResult = res;
				this.rootStore.loadersStore.setLoadingModal(false);
			});
		} catch (error) {
			this.rootStore.loadersStore.setLoadingModal(false);
		}
	};

	@action setCurrentSelectedElectronicDocument = (
		electronicDocument: IElectronicDocument | undefined
	) => {
		if (electronicDocument) {
			this.electronicDocumentById = electronicDocument;
		} else {
			this.electronicDocumentById = undefined;
		}
	};

	@action removeAllPriorApprovals = () => {
		this.allOpen = undefined;
		this.allPriorApprovals = undefined;
		this.setPeriod(
			moment().utc().subtract(1, 'month').startOf('month').toDate(),
			moment().utc().toDate()
		);
	};
}
