import { FormInstance } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import { Button, notification, Popconfirm } from 'antd';

interface IProps {
	form: FormInstance;
	closeModal: () => Promise<void>;
	openModal: (id: number) => Promise<void>;
}

const ActionButtons: React.FC<IProps> = ({ form, closeModal, openModal }) => {
	const rootStore = useContext(RootStoreContext);

	const {
		electronicDocumentById,
		requestChanges,
		getBackInOpenMode,
		approve,
		reject,
		getElectronicDocumentById,
	} = rootStore.electronicDocumentsStore;

	const requestChangesAction = async (id: number) => {
		await requestChanges(id);
		await getElectronicDocumentById(id);
	};

	const getBackInOpenModeAction = async (id: number) => {
		await getBackInOpenMode(id);
		await getElectronicDocumentById(id);
	};

	const approveAction = async (id: number) => {
		await approve(id);
		await closeModal();
	};

	const rejectAction = async (id: number, message: string) => {
		await reject(id, message);
		await closeModal();
	};

	return (
		<Fragment>
			<div
				style={{
					textAlign: 'center',
					display:
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Approved ||
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Rejected
							? 'none'
							: 'inherit',
				}}>
				<Popconfirm
					destroyTooltipOnHide={
						electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.OpenForModification
					}
					title='Отваряне за редакция?'
					onConfirm={async () =>
						await requestChangesAction(electronicDocumentById!.id)
					}>
					<Button
						type='primary'
						shape='round'
						className={'btn-primary'}
						hidden={
							electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.OpenForModification
						}>
						Отваряне за редакция
					</Button>
				</Popconfirm>

				<Popconfirm
					destroyTooltipOnHide={
						electronicDocumentById?.documentStatus !==
						Constants.DocumentProcessStatus.OpenForModification
					}
					title='Върни статуса на отворен?'
					onConfirm={async () =>
						await getBackInOpenModeAction(
							electronicDocumentById!.id
						)
					}>
					<Button
						type='primary'
						shape='round'
						className={'btn-primary'}
						hidden={
							electronicDocumentById?.documentStatus !==
							Constants.DocumentProcessStatus.OpenForModification
						}>
						Върни статуса на ИПО на отворен
					</Button>
				</Popconfirm>

				<Popconfirm
					destroyTooltipOnHide={
						electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.OpenForModification
					}
					title='Потвърждаване?'
					onConfirm={async () =>
						await approveAction(electronicDocumentById!.id)
					}>
					<Button
						hidden={
							electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.OpenForModification
						}
						type='primary'
						shape='round'
						className={'btn-primary'}>
						Потвърждаване
					</Button>
				</Popconfirm>
				<Popconfirm
					destroyTooltipOnHide={
						electronicDocumentById?.documentStatus ===
						Constants.DocumentProcessStatus.OpenForModification
					}
					title='Отхвърляне?'
					onConfirm={async () => {
						if (!form.getFieldValue('insuranceCompanyNotes')) {
							notification.error({
								message:
									'За да откажете ИПО трябва да въведете резолюция или допълнитена информация.',
								className: 'success-messages',
								duration: 5,
							});
						} else {
							await rejectAction(
								electronicDocumentById!.id,
								form.getFieldValue('insuranceCompanyNotes')
							);
						}
					}}>
					<Button
						hidden={
							electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.OpenForModification
						}
						type='primary'
						shape='round'
						className={'btn-danger'}>
						Отхвърляне
					</Button>
				</Popconfirm>
			</div>
		</Fragment>
	);
};

export default observer(ActionButtons);
