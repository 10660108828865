class DocumentProcessStatus {
	static readonly Registered = 'Регистриран';
	static readonly Open = 'Отворен';
	static readonly OpenForModification = 'Отворен за корекция';
	static readonly Approved = 'Потвърден';
	static readonly Rejected = 'Отхвърлен';
}

class DocumentType {
	static readonly AmbSheet = 'Амбулаторен лист';
	static readonly MedicalReferral = 'Медицинско назначение';
	static readonly LabResults = 'Лабораторни резултати';
	static readonly PriorApproval = 'Искане за предварително одобрение';
	static readonly DeseaseHistory = 'История на заболяването';
	static readonly StageEpicrisis = 'Етапна епикриза';
	static readonly AuthorizationByPatient =
		'Декларация за упълномощаване от пациент';
	static readonly Prescription = 'Рецепта';
	static readonly Preventions = 'Карта профилактика';
	static readonly Other = 'Друг документ';
}

class MedicalReferralStatus {
	static readonly NotPerformed = 'Свободен';
	static readonly Performed = 'Запазен';
}

class InsuranceContractStatus {
	static readonly Active = 'Активна застраховка';
	static readonly Expired = 'Изтекла застраховка';
	static readonly Suspended = 'Прекратена застраховка';
}

class AttachmentType {
	static readonly ElectronicDocument = 'Електронен';
	static readonly PhysicalCopy = 'Сканиран';
}

class Common {
	static readonly FullDate_Format = 'DD.MM.YYYY HH:mm:ss';
	static readonly FullDate_Format_Without_Seconds = 'DD.MM.YYYY HH:mm';
	static readonly ShortDate_Format = 'DD.MM.YYYY';
}

class FormMessages {
	static readonly RequiredField = 'Полето е задължително!';
}

class AccessLevel {
	static readonly Certificate = 'Certificate';
	static readonly Credentials = 'Credentials';
}

class ClaimProcessStatus {
	static readonly New = 'Нов';
	static readonly Processing = 'Депозиран';
	static readonly ChangeRequested = 'За корекция';
	static readonly Processed = 'Обработен';
}

const ReactSuiteCalendarLocale = {
	sunday: 'нд',
	monday: 'пн',
	tuesday: 'вт',
	wednesday: 'ср',
	thursday: 'чт',
	friday: 'пт',
	saturday: 'сб',
	ok: 'OK',
	today: 'Днес',
	yesterday: 'Вчера',
	last7Days: 'Последна седмица',
	hours: 'Часове',
	minutes: 'Минути',
	seconds: 'Секунди',
	January: 'Януари',
	February: 'Февруари',
	March: 'Март',
	April: 'Април',
	May: 'Май',
	june: 'Юни',
	july: 'Юли',
	august: ' Август',
	september: 'Септември',
	october: 'Октомври',
	november: 'Ноември',
	december: 'Декември',
};

export default {
	DocumentProcessStatus,
	DocumentType,
	MedicalReferralStatus,
	InsuranceContractStatus,
	AttachmentType,
	Common,
	FormMessages,
	AccessLevel,
	ClaimProcessStatus,
	ReactSuiteCalendarLocale,
};
