import React, { Fragment, useContext, useEffect } from 'react';
import { Switch, message, Button, Spin, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import Constants from '../../app/constants/constants';
import { RootStoreContext } from '../../app/stores/rootStore';

interface IProps {
	closeModal: () => Promise<void>;
}

const AccountManagerModule: React.FC<IProps> = ({ closeModal }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		getAllUsers,
		userById,
		getUserById,
		currentSelectedUserId,
		editUser,
		loadingInitial,
		activateUser,
		deactivateUser,
		deleteUser,
	} = rootStore.userManagementStore;

	const [form] = Form.useForm();

	const handleFormSubmit = async (values: any) => {
		const { ...userById } = values;
		await editUser(userById!);
		await closeModal();
		await getAllUsers();
	};

	useEffect(() => {
		getUserById(currentSelectedUserId!);
	}, [currentSelectedUserId, getUserById]);

	return (
		<Fragment>
			<Spin tip='Зареждане...' spinning={loadingInitial}>
				<Form
					initialValues={{
						FullName: userById?.fullName,
						Username: userById?.username,
						Email: userById?.email || undefined,
						IsActive: userById?.isActive,
						Id: userById?.id,
						Egn: userById?.egn || undefined,
					}}
					layout={'vertical'}
					form={form}
					onFinish={handleFormSubmit}
					key={userById?.id}>
					<Form.Item
						name='FullName'
						label='Име'
						rules={[
							{
								required: true,
								message: Constants.FormMessages.RequiredField,
							},
							({ isFieldsTouched }) => ({
								validator(rule, value) {
									if (isFieldsTouched() === false) {
										return Promise.reject(
											message.warn(
												'Няма направена промяна',
												5
											)
										);
									}
									return Promise.resolve();
								},
							}),
						]}>
						<Input key={userById?.fullName} />
					</Form.Item>
					<Form.Item
						name='Username'
						label='Потребителско име'
						rules={[
							{
								required: true,
								message: Constants.FormMessages.RequiredField,
							},
							{
								type: 'email',
								message: 'Моля, попълнете валиден имейл!',
							},
							({ isFieldsTouched }) => ({
								validator(rule, value) {
									if (isFieldsTouched() === false) {
										return Promise.reject(
											message.warn(
												'Няма направена промяна',
												5
											)
										);
									}
									return Promise.resolve();
								},
							}),
						]}>
						<Input key={userById?.username} />
					</Form.Item>
					<Form.Item
						name='Email'
						label='Имейл '
						rules={[
							{
								type: 'email',
								message: 'Моля, попълнете валиден имейл!',
							},
							({ isFieldsTouched }) => ({
								validator(rule, value) {
									if (isFieldsTouched() === false) {
										return Promise.reject(
											message.warn(
												'Няма направена промяна',
												5
											)
										);
									}
									return Promise.resolve();
								},
							}),
						]}>
						<Input key={userById?.email} />
					</Form.Item>
					<Form.Item name='IsActive'>
						<Switch
							disabled={true}
							defaultChecked={userById?.isActive}
							checkedChildren='Активен'
							unCheckedChildren='Неактивен'></Switch>
					</Form.Item>
					<Form.Item name='Id'>
						<Fragment>
							<Button
								type='primary'
								shape='round'
								className='btn-primary'
								htmlType='submit'>
								Запази
							</Button>
							{userById.isActive ? (
								<Popconfirm
									icon={
										<QuestionCircleOutlined
											style={{ color: 'red' }}
										/>
									}
									placement='top'
									title='Сигурни ли сте, че искате да деактивирате потребител?'
									onConfirm={async () => {
										await deactivateUser(
											currentSelectedUserId!
										);
										await closeModal();
										await getAllUsers();
									}}
									okText='Продължи'
									cancelText='Не'>
									<Button
										type='primary'
										shape='round'
										className='btn-groupe'
										style={{
											background:
												'radial-gradient(circle, rgba(204,13,13,0.8) 28%, rgba(230,22,22,0.8) 100%)',
										}}>
										Деактивиране
									</Button>
								</Popconfirm>
							) : userById?.lastLogin ? (
								<Button
									type='primary'
									shape='round'
									className='btn-groupe'
									style={{
										background: '#234254',
									}}
									onClick={async () => {
										await activateUser(
											currentSelectedUserId!
										);
										await closeModal();
										await getAllUsers();
									}}>
									Активиране
								</Button>
							) : (
								<Popconfirm
									icon={
										<QuestionCircleOutlined
											style={{ color: 'red' }}
										/>
									}
									placement='top'
									title='Сигурни ли сте, че искате да изтриете потребител?'
									onConfirm={async () => {
										await deleteUser(
											currentSelectedUserId!
										);
										await closeModal();
										await getAllUsers();
									}}
									okText='Продължи'
									cancelText='Не'>
									<Button
										type='primary'
										shape='round'
										className='btn-groupe'
										style={{
											background:
												'radial-gradient(circle, rgba(204,13,13,0.8) 28%, rgba(230,22,22,0.8) 100%)',
										}}>
										Изтриване
									</Button>
								</Popconfirm>
							)}
						</Fragment>
					</Form.Item>
				</Form>
			</Spin>
		</Fragment>
	);
};

export default observer(AccountManagerModule);
