import React, { useContext, useEffect } from 'react';
import { Badge, Button, Avatar, Tooltip, Dropdown } from 'antd';
import {
	FileUnknownOutlined,
	UserOutlined,
	LogoutOutlined,
	NotificationOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import { HubConnectionState } from '@microsoft/signalr';

const NavBar: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { currentUser, logout } = rootStore.identityStore;
	const { statisticsInformation } = rootStore.electronicDocumentsStore;

	const {
		createNotificationHubConnection,
		notificationHubConnection,
	} = rootStore.notificationStore;

	const {
		createMessangerHubConnection,
		messangerHubConnection,
	} = rootStore.messangerStore;

	useEffect(() => {
		if (rootStore.identityStore.currentUser) {
			if (
				notificationHubConnection?.state !==
				HubConnectionState.Connected
			) {
				createNotificationHubConnection();
			}
			if (
				messangerHubConnection?.state !== HubConnectionState.Connected
			) {
				createMessangerHubConnection();
			}
		}
	}, [createNotificationHubConnection, rootStore.identityStore.currentUser]);

	let notify = `Необработени искания: ${
		rootStore.electronicDocumentsStore.statisticsInformation
			?.totalUnperformed ?? 0
	}, от които спешни - ${
		rootStore.electronicDocumentsStore.statisticsInformation
			?.totalImportantUnperformed ?? 0
	}.`;

	let menu =
		rootStore.notificationStore.notReadNotifications &&
		rootStore.notificationStore.notReadNotifications?.length > 0 ? (
			<div
				style={{
					background: 'white',
					boxShadow: '0px 0px 13px 6px #6363637d',
					maxHeight: 300,
					overflow: 'scroll',
				}}>
				{rootStore.notificationStore.notReadNotifications?.map((a) => (
					<div
						style={{
							maxWidth: 500,
							margin: 5,
							borderBottom: '1px solid rgb(99 99 99 / 65%)',
						}}>
						{a.content}
					</div>
				))}
			</div>
		) : (
			<div></div>
		);

	return (
		<div className='navBar'>
			<div className='navBarItems'>
				<div className='companyName-div'>
					{currentUser?.companyName
						? currentUser.companyName
						: 'CompanyName'}
				</div>
				<Dropdown placement='bottomCenter' overlay={menu}>
					<Link to='/notifications'>
						<Badge
							count={
								rootStore.notificationStore
									.unreadNotificationsCount
							}
							className='navBar-badge'>
							<Avatar
								className='navBar-icon'
								icon={<NotificationOutlined />}
							/>
						</Badge>
					</Link>
				</Dropdown>
				<Link to='/priorApproval'>
					<Tooltip
						color={
							statisticsInformation?.totalImportantUnperformed ===
							0
								? 'orange'
								: 'red'
						}
						title={notify}>
						<Badge
							style={{
								backgroundColor:
									statisticsInformation?.totalImportantUnperformed ===
									0
										? 'orange'
										: 'red',
								color:
									statisticsInformation?.totalImportantUnperformed ===
									0
										? 'black'
										: 'white',
							}}
							count={statisticsInformation?.totalUnperformed}
							className='navBar-badge'>
							<Avatar
								className='navBar-icon'
								icon={<FileUnknownOutlined />}
							/>
						</Badge>
					</Tooltip>
				</Link>
				<Link to='/profile'>
					<span className='ant-dropdown-link'>
						{currentUser?.userName}
						<Avatar
							className='navBar-avatar'
							icon={<UserOutlined />}
						/>
					</span>
				</Link>
				<Button
					className='btn-primary'
					shape='round'
					icon={<LogoutOutlined />}
					onClick={() => logout()}>
					Изход
				</Button>
			</div>
		</div>
	);
};
export default observer(NavBar);
