import React, { Fragment } from 'react';

import AccountManagerTable from './AccountManagerTable';
import { observer } from 'mobx-react-lite';

const AccauntsManager: React.FC = () => {
	return (
		<Fragment>
			<AccountManagerTable />
		</Fragment>
	);
};
export default observer(AccauntsManager);
