import { Button, Form } from 'antd';
import React, { Fragment } from 'react';
import { IElectronicDocument } from '../../app/models/electronicDocuments/ElectronicDocument';
import Constants from '../../app/constants/constants';
import InsuranceCompanyNotesFormItem from '../../app/layout/FormItems/InsuranceCompanyNotesFormItem';
import ActionButtons from './ActionButtons';
import PrintElectronicDocument from '../print/PrintElectronicDocument';
import { PrinterOutlined } from '@ant-design/icons';

interface IProps {
	electronicDocumentById: IElectronicDocument;
	handlePrint: any;
	componentRef: any;
	closeModal: () => Promise<void>;
	openModal: (id: number) => Promise<void>;
}

const ResolutionFormItem: React.FC<IProps> = ({
	electronicDocumentById,
	handlePrint,
	componentRef,
	closeModal,
	openModal,
}) => {
	const [form] = Form.useForm();
	return (
		<Fragment>
			<Form
				form={form}
				style={{
					display:
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Approved ||
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Rejected
							? 'none'
							: 'inherit',
				}}>
				<InsuranceCompanyNotesFormItem
					isActionRequireMessageTriggered={false}
				/>
			</Form>
			<div
				style={{
					display:
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Approved ||
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Rejected
							? 'inherit'
							: 'none',
				}}>
				<h3> Резолюция: </h3>

				<div style={{ fontSize: 18 }}>
					{electronicDocumentById?.insuranceCompanyNotes}
				</div>
				<br />
			</div>
			<Button
				type='primary'
				className='btn-groupe'
				shape='round'
				style={{
					float:
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Approved ||
						electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Rejected
							? 'unset'
							: 'left',
					margin: 0,
					backgroundColor: '#234254',
					borderRadius: 20,
				}}
				onClick={handlePrint}>
				<PrinterOutlined /> Принтирай
			</Button>
			<div style={{ display: 'none' }}>
				<PrintElectronicDocument ref={componentRef} />
			</div>
			<ActionButtons
				form={form}
				closeModal={closeModal}
				openModal={openModal}
			/>
		</Fragment>
	);
};

export default ResolutionFormItem;
