import { action, configure } from 'mobx';
import UserManagementStore from './userManagementStore';
import IdentityStore from './identityStore';
import { createContext } from 'react';
import ElectronicDocumentsStore from './electronicDocumentsStore';
import LoadersStore from './loadersStore';
import MessangerStore from './messangerStore';
import NotificationStore from './notificationStore';
import ClaimStore from './claimStore';
import HelpdeskStore from './helpdeskStore';

configure({ enforceActions: 'always' });

export class RootStore {
	userManagementStore: UserManagementStore;
	identityStore: IdentityStore;
	electronicDocumentsStore: ElectronicDocumentsStore;
	loadersStore: LoadersStore;
	messangerStore: MessangerStore;
	notificationStore: NotificationStore;
	claimStore: ClaimStore;
	helpdeskStore: HelpdeskStore;

	constructor() {
		this.userManagementStore = new UserManagementStore(this);
		this.identityStore = new IdentityStore(this);
		this.electronicDocumentsStore = new ElectronicDocumentsStore(this);
		this.loadersStore = new LoadersStore(this);
		this.messangerStore = new MessangerStore(this);
		this.notificationStore = new NotificationStore(this);
		this.claimStore = new ClaimStore(this);
		this.helpdeskStore = new HelpdeskStore(this);
	}

	@action setBaseConfiguration = async () => {
		if (!this.helpdeskStore.helpdeskCategories) {
			await this.helpdeskStore.getHelpdeskCategories();
		}
		if (!this.helpdeskStore.helpdeskModules) {
			await this.helpdeskStore.getHelpdeskModules();
		}
	};
}

export const RootStoreContext = createContext(new RootStore());
