import { action, observable } from 'mobx';
import { RootStore } from './rootStore';

export default class LoadersStore {
	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable loadingAllTable = false;
	@observable loadingModal = false;
	@observable loadingEmailSending = false;
	@observable loadingCheckForm = false;
	@observable loadingButtons = false;
	@observable loadingNotifications = false;

	@action setLoadingAllTables = (value: boolean) => {
		this.loadingAllTable = value;
	};

	@action setLoadingModal = (value: boolean) => {
		this.loadingModal = value;
	};

	@action setLoadingEmailSending = (value: boolean) => {
		this.loadingEmailSending = value;
	};

	@action setLoadingCheckForm = (value: boolean) => {
		this.loadingCheckForm = value;
	};

	@action setLoadingButtons = (value: boolean) => {
		this.loadingButtons = value;
	};
	@action setLoadingNotifications = (value: boolean) => {
		this.loadingNotifications = value;
	};
}
