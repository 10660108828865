import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Drawer, Menu, Avatar, Button } from 'antd';
import { NavBar, Icon } from 'antd-mobile';
import {
	LayoutOutlined,
	NotificationOutlined,
	UserOutlined,
	LogoutOutlined,
	UsergroupAddOutlined,
	FileUnknownOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { RootStoreContext } from '../../../app/stores/rootStore';
import { HubConnectionState } from '@microsoft/signalr';

const DrawerBar: React.FC = () => {
	const [open, setOpen] = useState(false);

	const rootStore = useContext(RootStoreContext);
	const { currentUser, logout, accessToken } = rootStore.identityStore;
	const {
		createNotificationHubConnection,
		notificationHubConnection,
	} = rootStore.notificationStore;

	const {
		createMessangerHubConnection,
		messangerHubConnection,
	} = rootStore.messangerStore;

	useEffect(() => {
		if (rootStore.identityStore.currentUser) {
			if (
				notificationHubConnection?.state !==
				HubConnectionState.Connected
			) {
				createNotificationHubConnection();
			}
			if (
				messangerHubConnection?.state !== HubConnectionState.Connected
			) {
				createMessangerHubConnection();
			}
		}
	}, [createNotificationHubConnection, rootStore.identityStore.currentUser]);
	const onOpenChange = () => {
		setOpen(!open);
	};

	const location = useLocation();

	return (
		<div>
			{accessToken ? (
				<Fragment>
					<NavBar
						icon={<Icon type='ellipsis' />}
						onLeftClick={onOpenChange}
						rightContent={[
							<Button
								className='btn-primary'
								shape='round'
								icon={<LogoutOutlined />}
								onClick={() => logout()}></Button>,
						]}>
						<Link to='/profile'>
							<span className='ant-dropdown-link'>
								{currentUser?.userName}
								<Avatar
									className='navBar-avatar'
									icon={<UserOutlined />}
								/>
							</span>
						</Link>
					</NavBar>
					<Drawer
						placement='left'
						closable={false}
						onClose={onOpenChange}
						visible={open}>
						<Menu
							defaultOpenKeys={['sub1']}
							mode='inline'
							theme='dark'
							className='sidebar'
							selectedKeys={[location.pathname]}>
							<Menu.Item
								key='/'
								icon={<LayoutOutlined />}
								onClick={onOpenChange}>
								Начало <Link to='/' />
							</Menu.Item>
							<Menu.Item
								key='/priorApproval'
								icon={<FileUnknownOutlined />}
								onClick={onOpenChange}>
								Обработка на ИПО <Link to='/priorApproval' />
							</Menu.Item>
							<Menu.Item
								key='/claims'
								icon={<FileUnknownOutlined />}>
								Обработка на искове <Link to='/claims' />
							</Menu.Item>
							<Menu.Item
								key='/notifications'
								icon={<NotificationOutlined />}
								onClick={onOpenChange}>
								Известия <Link to='/notifications' />
							</Menu.Item>
							<Menu.Item
								style={{
									display:
										currentUser?.manager?.length > 0
											? 'block'
											: 'none',
								}}
								key='/accounts'
								icon={<UsergroupAddOutlined />}>
								Потребители <Link to='/accounts' />
							</Menu.Item>
							<Menu.Item
								key='/profile'
								icon={<UserOutlined />}
								onClick={onOpenChange}>
								Профил <Link to='/profile' />
							</Menu.Item>
						</Menu>
					</Drawer>
				</Fragment>
			) : (
				<div style={{ display: 'none' }}></div>
			)}
		</div>
	);
};
export default DrawerBar;
