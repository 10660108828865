import React from 'react';
import PrintDetailsSheet from './PrintDetailsSheet';

class PrintDetailsOnClaims extends React.Component {
	render() {
		return (
			<div>
				<PrintDetailsSheet />
			</div>
		);
	}
}
export default PrintDetailsOnClaims;
