import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import Moment from 'react-moment';
import { Button, Modal, Form, Input, Checkbox, Switch } from 'antd';
import ElectonicDocumentPreveiw from './ElectonicDocumentPreveiw';
import { FileSearchOutlined } from '@ant-design/icons';
import { IDownloadAttachment } from '../models/electronicDocuments/DownloadAttachment';
import {
	IDetailEdit,
	IElectronicDocumentEdit,
} from '../models/claims/ClaimEditSummary';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
	closePreview: () => Promise<void>;
}

const AmbulatoryDocumentPreview: React.FC<IProps> = ({ closePreview }) => {
	const [form] = Form.useForm();
	const rootStore = useContext(RootStoreContext);
	const {
		electronicDocumentById,
		downloadAttachment,
		getElectronicSubDocumentById,
	} = rootStore.electronicDocumentsStore;

	const {
		editElectronicDocument,
		setIsAmbFormTouched,
		isAmbFormTouched,
		getClaimById,
		claimById,
	} = rootStore.claimStore;

	const [show, setShow] = useState(false);
	const [detailsState, setDetailsState] = useState([] as IDetailEdit[]);

	const preview = async (id: number) => {
		if (id) {
			await getElectronicSubDocumentById(id);
		}
		setShow(true);
	};

	const download = async (
		patientUid: string,
		fileName: string,
		documentType: string
	) => {
		let model: IDownloadAttachment = {
			documentType: documentType,
			patientUid: patientUid,
			fileName: fileName,
		};

		await downloadAttachment(model);

		if (rootStore.electronicDocumentsStore.attachmentDownloadResult) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentsStore
						.attachmentDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${model.documentType}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const editFormSubmit = async (values: any) => {
		let editModel: IElectronicDocumentEdit = {
			id: electronicDocumentById!.id,
			insuranceCompanyNotes: values.insuranceCompanyNotes,
			isForEditing: values.isForEditing,
			details: detailsState,
		};

		await editElectronicDocument(editModel);
		await getClaimById(claimById?.id);
		await closePreview();
		setIsAmbFormTouched(false);
	};

	return (
		<div style={{ margin: '10px', color: 'black', fontFamily: 'Calibri' }}>
			<h5
				style={{
					textAlign: 'center',
				}}>
				{electronicDocumentById?.medicalFacilityIssuerName}{' '}
			</h5>
			<h5
				style={{
					textAlign: 'center',
				}}>
				{' '}
				{electronicDocumentById?.medicalFacilityIssuerCity}
			</h5>
			<h5
				style={{
					textAlign: 'center',
				}}>
				{' '}
				РЗИ: {electronicDocumentById?.medicalFacilityIssuerRziCode}
			</h5>
			{electronicDocumentById?.documentType.toString() ===
			Constants.DocumentType.Preventions ? (
				<div>
					<h4 style={{ fontWeight: 'bolder' }}>
						{' '}
						Профилактична дейност №{' '}
						{electronicDocumentById?.documentNumber}{' '}
					</h4>
					<h4 style={{ fontWeight: 'bolder' }}>
						{' '}
						Дата:{' '}
						<Moment format={Constants.Common.ShortDate_Format}>
							{electronicDocumentById?.preventionDateFrom}
						</Moment>{' '}
						-{' '}
						<Moment format={Constants.Common.ShortDate_Format}>
							{electronicDocumentById?.preventionDateTo}
						</Moment>{' '}
					</h4>
					<h4>
						Застрахователна компания: "
						{electronicDocumentById?.insuranceCompanyName}"
					</h4>
					<div>
						<div>Пациент: </div>
						<h5 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
							ЕГН: {electronicDocumentById?.patientUid}{' '}
						</h5>
						<h5 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
							Име: {electronicDocumentById?.patientName}
						</h5>
						<h5 style={{ marginLeft: 10, fontWeight: 'bolder' }}>
							Номер на карта:{' '}
							{electronicDocumentById?.patientCard}
						</h5>
					</div>
					<br />
				</div>
			) : (
				<div>
					<h4 style={{ fontWeight: 'bolder' }}>
						{electronicDocumentById?.documentType.toString() ===
						Constants.DocumentType.AmbSheet || electronicDocumentById?.documentType.toString() ===
						Constants.DocumentType.DeseaseHistory
							? electronicDocumentById?.documentType
							: 'МДД'}{' '}
						№ {electronicDocumentById?.documentNumber}{' '}
						<div
							style={{ float: 'right', display: 'inline-block' }}>
							дата:{' '}
							<Moment
								format={
									electronicDocumentById?.documentType.toString() ===
									Constants.DocumentType.AmbSheet|| electronicDocumentById?.documentType.toString() ===
									Constants.DocumentType.DeseaseHistory
										? Constants.Common
												.FullDate_Format_Without_Seconds
										: Constants.Common.ShortDate_Format
								}>
								{electronicDocumentById?.documentDate}
							</Moment>{' '}
						</div>
					</h4>
					<h4
						style={{
							display:
								electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.AmbSheet
									? 'block'
									: 'none',
						}}>
						Тип:{' '}
						{electronicDocumentById?.isPrimary === false
							? 'Вторичен'
							: 'Първичен'}{' '}
					</h4>
					<h4>
						Застрахователна компания: "
						{electronicDocumentById?.insuranceCompanyName}"
					</h4>
					<div>
						<div
							style={{
								width: '49%',
								display: ' inline-block',
								border: '1px solid',
								margin: 5,
								marginLeft: 0,
								padding: 5,
								verticalAlign: 'top',
								minHeight: 170,
							}}>
							<div>Пациент: </div>
							<h5
								style={{
									marginLeft: 10,
									fontWeight: 'bolder',
								}}>
								ЕГН: {electronicDocumentById?.patientUid}{' '}
							</h5>
							<h5
								style={{
									marginLeft: 10,
									fontWeight: 'bolder',
								}}>
								Име: {electronicDocumentById?.patientName}
							</h5>
							<h5
								style={{
									marginLeft: 10,
									fontWeight: 'bolder',
								}}>
								Номер на карта:{' '}
								{electronicDocumentById?.patientCard}
							</h5>
						</div>
						<div
							style={{
								width: '49%',
								float: 'right',
								display: ' inline-block',
								border: '1px solid',
								margin: 5,
								marginRight: 0,
								padding: 5,
								verticalAlign: 'top',
								minHeight: 170,
							}}>
							<div>Лекар: </div>
							<h5
								style={{
									marginLeft: 10,
									fontWeight: 'bolder',
								}}>
								УИН: {electronicDocumentById?.issuerUin}{' '}
							</h5>
							<h5
								style={{
									marginLeft: 10,
									fontWeight: 'bolder',
								}}>
								Име: {electronicDocumentById?.issuerName}
							</h5>
							<h5
								style={{
									marginLeft: 10,
									fontWeight: 'bolder',
								}}>
								Специалност:{' '}
								{electronicDocumentById?.issuerSpecialityCode} -{' '}
								{electronicDocumentById?.issuerSpecialityName}
							</h5>
						</div>
					</div>
					<br />
					<div style={{ fontSize: 16 }}>
						<span
							style={{
								fontWeight: 'bolder',
								fontStyle: 'italic',
								fontSize: 16,
							}}>
							Основна диагноза:
						</span>{' '}
						{electronicDocumentById?.mkbCode} -{' '}
						{electronicDocumentById?.mkbName}{' '}
					</div>
					<br />

					<div
						style={{
							display:
								electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.AmbSheet || electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.DeseaseHistory
									? 'block'
									: 'none',
						}}>
						<div
							style={{
								width: '100%',
								border: '1px solid',
								marginBottom: '3px',
								padding: 5,
							}}>
							<div
								style={{
									fontWeight: 'bolder',
									fontStyle: 'italic',
									fontSize: 12,
								}}>
								Анамнеза
							</div>
							<div>
								{electronicDocumentById?.patientMedicalHistory}
							</div>
						</div>
						<br />
					</div>

					<div
						style={{
							display:
								electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.AmbSheet || electronicDocumentById?.documentType.toString() ===
								Constants.DocumentType.DeseaseHistory
									? 'block'
									: 'none',
						}}>
						<div
							style={{
								width: '100%',
								border: '1px solid',
								marginBottom: '3px',
								padding: 5,
							}}>
							<div
								style={{
									fontWeight: 'bolder',
									fontStyle: 'italic',
									fontSize: 12,
								}}>
								Обективно състояние
							</div>
							<div>
								{electronicDocumentById?.patientHealthStatus}
							</div>
						</div>
						<br />
					</div>

					<div
						style={{
							display: electronicDocumentById?.patientExaminations
								? 'block'
								: 'none',
						}}>
						<div
							style={{
								width: '100%',
								border: '1px solid',
								marginBottom: '3px',
								padding: 5,
							}}>
							<div
								style={{
									fontWeight: 'bolder',
									fontStyle: 'italic',
									fontSize: 12,
								}}>
								Изследвания
							</div>
							<div>
								{electronicDocumentById?.patientExaminations}
							</div>
						</div>
						<br />
					</div>

					<div
						style={{
							display: electronicDocumentById?.patientTherapy
								? 'block'
								: 'none',
						}}>
						<div
							style={{
								width: '100%',
								border: '1px solid',
								marginBottom: '3px',
								padding: 5,
							}}>
							<div
								style={{
									fontWeight: 'bolder',
									fontStyle: 'italic',
									fontSize: 12,
								}}>
								Терапия
							</div>
							<div>{electronicDocumentById?.patientTherapy}</div>
						</div>
						<br />
					</div>
				</div>
			)}

			<h5> Извършени дейности: </h5>
			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 11,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '10%' }}>
							{' '}
							Код по МКБ 9КМ / НЗОК{' '}
						</th>
						<th style={{ border: '1px solid ', width: '35%' }}>
							{' '}
							Дейност{' '}
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Спец.{' '}
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							{' '}
							Допълнителна информация{' '}
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Ед.цена{' '}
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Брой
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Сума{' '}
						</th>
						<th style={{ border: '1px solid ', width: '5%' }}>
							{' '}
							Приет{' '}
						</th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{electronicDocumentById?.details.map((detail) => (
						<tr>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.nhifCode}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.name}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.specialityCode}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.description}{' '}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'right',
								}}>
								{' '}
								{detail.price}{' '}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.count}{' '}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'right',
								}}>
								{' '}
								{detail.totalAmount}{' '}
							</td>
							<td style={{ border: '1px solid ' }}>
								<Checkbox
									disabled={
										electronicDocumentById?.claimProcessStatus !==
										Constants.ClaimProcessStatus.Processing
									}
									onChange={(e) => {
										setDetailsState([
											...detailsState,
											{
												id: detail?.id,
												isPayable: e.target.checked,
											},
										]);
										setIsAmbFormTouched(true);
									}}
									defaultChecked={detail?.isPayable}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div style={{ textAlign: 'end' }}>
				Общо:{' '}
				{electronicDocumentById?.details
					.map((detail) => parseFloat(detail.totalAmount))
					.reduce((a, b) => a + b)
					.toFixed(2)}{' '}
				Общо платимо:{' '}
				{parseFloat(electronicDocumentById?.payableAmount).toFixed(2)}{' '}
			</div>
			<br />
			<span
				style={{
					display:
						electronicDocumentById?.attachments.length === 0
							? 'none'
							: 'inherit',
				}}>
				<h5>Други документи: </h5>
				<table
					style={{
						borderCollapse: 'collapse',
						textAlign: 'center',
						width: '100%',
						color: 'black',
						fontSize: 11,
					}}>
					<thead>
						<tr style={{ border: '1px solid ' }}>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Тип на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								{' '}
								Съдържание тип{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								{' '}
								Номер на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								{' '}
								Дата на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '25%',
								}}>
								Допълнителна информация / Лекар
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								Запазване
							</th>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid ' }}>
						{electronicDocumentById?.attachments.map(
							(attachment) => (
								<tr key={uuidv4()}>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.attachmentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.documentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentNumber}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentDate !== null ? (
											<Moment
												format={
													Constants.Common
														.ShortDate_Format
												}>
												{attachment.eDocumentDate}
											</Moment>
										) : (
											''
										)}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.description
											? attachment.description
											: attachment.eDocumentDoctorName}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										<Button
											type='link'
											onClick={() =>
												attachment.attachmentType ===
												Constants.AttachmentType
													.PhysicalCopy
													? download(
															electronicDocumentById?.patientUid,
															attachment.fileName,
															attachment.documentType
													  )
													: preview(
															attachment!
																.toAttachmentId
													  )
											}>
											<FileSearchOutlined
												style={{
													color: '#e48800',
													fontSize: 20,
													fontWeight: 700,
												}}
											/>
										</Button>
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
			</span>
			<Form
				form={form}
				style={{ margin: '10px 0px' }}
				onFinish={async (values: any) => await editFormSubmit(values)}>
				<Form.Item name='isForEditing'>
					<Switch
						disabled={
							electronicDocumentById?.claimProcessStatus !==
							Constants.ClaimProcessStatus.Processing
						}
						onChange={() => setIsAmbFormTouched(true)}
						defaultChecked={electronicDocumentById?.isForEditing}
						checkedChildren='За корекция'
						unCheckedChildren='Включи за корекция'></Switch>
				</Form.Item>
				<Form.Item
					name='insuranceCompanyNotes'
					initialValue={electronicDocumentById?.insuranceCompanyNotes}
					rules={[
						{
							required: true,
							message: 'Полето е задължително!',
						},
					]}>
					<Input.TextArea
						disabled={
							electronicDocumentById?.claimProcessStatus !==
							Constants.ClaimProcessStatus.Processing
						}
						onInput={() => setIsAmbFormTouched(true)}
						placeholder='Моля, попълнете ако имате забележки към амбулаторния лист или желаете да откажете дадена дейност!'
						style={{ border: '1px solid black', float: 'left' }}
					/>
				</Form.Item>
				<Form.Item>
					<Button
						disabled={
							electronicDocumentById?.claimProcessStatus !==
								Constants.ClaimProcessStatus.Processing ||
							!isAmbFormTouched
						}
						className='btn-blue'
						htmlType='submit'>
						Запази и затвори
					</Button>
				</Form.Item>
			</Form>
			<Modal
				className='appointment-modal'
				title={'Преглед на електронен документ'}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={async () => setShow(false)}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<ElectonicDocumentPreveiw />
			</Modal>
		</div>
	);
};

export default observer(AmbulatoryDocumentPreview);
