import { Button, Popconfirm, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import Moment from 'react-moment';
import AmbulatoryDocumentPreview from '../../app/layout/AmbulatoryDocumentPreview';
import { RootStoreContext } from '../../app/stores/rootStore';
import constants from '../../app/constants/constants';
import {
	FileSearchOutlined,
	CloseOutlined,
	PrinterOutlined,
	FileExcelOutlined,
} from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import PrintDocOnClaims from '../print/PrintDocOnClaims';

const ClaimsByIdDocumentsModal = () => {
	const rootStore = useContext(RootStoreContext);
	const {
		claimById,
		isAmbFormTouched,
		setIsAmbFormTouched,
		setIsPrintingClaimDoc,
	} = rootStore.claimStore;
	const {
		getElectronicDocumentById,
		exportClaimAttachmentsToExcel,
	} = rootStore.electronicDocumentsStore;

	const [show, setShow] = useState(false);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [rowKey, setRowKey] = useState();

	const preview = async (id: number) => {
		if (id) {
			await getElectronicDocumentById(id);
		}
		setShow(true);
	};

	const closePreviewAmbulatorySheet = async () => {
		await getElectronicDocumentById(undefined);
		setShow(false);
		setIsAmbFormTouched(false);
	};

	const componentRefDoc = useRef(null);
	const handlePrintDoc = useReactToPrint({
		content: () => componentRefDoc.current!,
	});

	const exportAttachmentsToExcel = async () => {
		await exportClaimAttachmentsToExcel(claimById?.id);

		if (
			rootStore.electronicDocumentsStore
				.claimAttachmentsExcelDownloadResult
		) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentsStore
						.claimAttachmentsExcelDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${claimById?.documentNumber}_documents.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const colums = [
		{
			title: '№',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			align: 'center' as 'center',
		},
		{
			title: 'От дата',
			dataIndex: 'documentDate',
			key: 'documentDate',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<Moment
					format={
						row.documentType === constants.DocumentType.AmbSheet ||
						row.documentType ===
							constants.DocumentType.DeseaseHistory
							? constants.Common.FullDate_Format_Without_Seconds
							: constants.Common.ShortDate_Format
					}>
					{text}
				</Moment>
			),
		},
		{
			title:claimById?.examinationType === '1' ? 'Вид' : '',
			dataIndex:
				claimById?.examinationType === '1' ? 'isPrimaryText' : '',
			key: claimById?.examinationType === '1' ? 'isPrimaryText' : '',
			align: 'center' as 'center',
		},
		{
			title: 'УИН',
			dataIndex: 'issuerUin',
			key: 'issuerUin',
			align: 'center' as 'center',
		},
		{
			title: 'Лекар',
			dataIndex: 'issuerName',
			key: 'issuerName',
			align: 'center' as 'center',
		},
		{
			title: 'Спец.',
			dataIndex: 'issuerSpecialityCode',
			key: 'issuerSpecialityCode',
			align: 'center' as 'center',
		},
		{
			title: 'ЕГН',
			dataIndex: 'patientUid',
			key: 'patientUid',
			align: 'center' as 'center',
		},
		{
			title: 'Име пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			align: 'center' as 'center',
		},
		{
			title: '№ карта',
			dataIndex: 'patientCard',
			key: 'patientCard',
			align: 'center' as 'center',
		},
		{
			title: 'МКБ код',
			dataIndex: 'mkbCode',
			key: 'mkbCode',
			align: 'center' as 'center',
		},
		{
			title: 'Сума',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			align: 'center' as 'center',
		},
		{
			title: 'Отказ. дейн.',
			dataIndex: 'notPayableDetailsCount',
			key: 'notPayableDetailsCount',
			align: 'center' as 'center',
		},
		{
			title: 'Сума (нова)',
			dataIndex: 'payableAmount',
			key: 'payableAmount',
			align: 'center' as 'center',
		},
		{
			title: 'Преглед',
			dataIndex: 'id',
			key: 'id',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<FileSearchOutlined
					onClick={() => preview(row?.id)}
					style={{
						color: '#e48800',
						fontSize: 20,
						fontWeight: 700,
					}}
				/>
			),
		},
		{
			title: '',
			dataIndex: 'isForEditing',
			key: 'isForEditing',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div>{row?.isForEditing ? 'За кор.' : ' '} </div>
			),
		},
	];

	const preventionColums = [
		{
			title: '№',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			align: 'center' as 'center',
		},
		{
			title: 'Дата',
			dataIndex: 'preventionDateFrom',
			key: 'preventionDateFrom',
			render: (row: any, record: any) => (
				<div className='table-div-centered'>
					<Moment format={constants.Common.ShortDate_Format}>
						{record.preventionDateFrom}
					</Moment>{' '}
					-{' '}
					<Moment format={constants.Common.ShortDate_Format}>
						{record.preventionDateTo}
					</Moment>
				</div>
			),
		},
		{
			title: 'ЕГН',
			dataIndex: 'patientUid',
			key: 'patientUid',
			align: 'center' as 'center',
		},
		{
			title: 'Име пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			align: 'center' as 'center',
		},
		{
			title: '№ карта',
			dataIndex: 'patientCard',
			key: 'patientCard',
			align: 'center' as 'center',
		},
		{
			title: 'Сума',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			align: 'center' as 'center',
		},
		{
			title: 'Отказ. дейн.',
			dataIndex: 'notPayableDetailsCount',
			key: 'notPayableDetailsCount',
			align: 'center' as 'center',
		},
		{
			title: 'Сума (нова)',
			dataIndex: 'payableAmount',
			key: 'payableAmount',
			align: 'center' as 'center',
		},
		{
			title: 'Преглед',
			dataIndex: 'id',
			key: 'id',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<FileSearchOutlined
					onClick={() => preview(row?.id)}
					style={{
						color: '#e48800',
						fontSize: 20,
						fontWeight: 700,
					}}
				/>
			),
		},
		{
			title: '',
			dataIndex: 'isForEditing',
			key: 'isForEditing',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div>{row?.isForEditing ? 'За кор.' : ' '} </div>
			),
		},
	];

	return (
		<div>
			<div style={{ display: 'none' }}>
				<PrintDocOnClaims ref={componentRefDoc} />
			</div>
			<Button
				key='print'
				type='primary'
				shape='round'
				style={{
					marginBottom: 5,
					borderRadius: 20,
					fontSize: 12,
				}}
				onClick={() => {
					setIsPrintingClaimDoc(true);
					setTimeout(() => {
						handlePrintDoc();
					}, 2000);
				}}>
				<PrinterOutlined /> Принтирай
			</Button>
			<Button
				key='export'
				type='primary'
				shape='round'
				style={{
					marginBottom: 5,
					borderRadius: 20,
					marginLeft: 5,
					fontSize: 12,
				}}
				onClick={async () => await exportAttachmentsToExcel()}>
				<FileExcelOutlined /> Изтегляне (xlsx)
			</Button>
			<Table
				columns={
					claimById?.examinationType === '3'
						? preventionColums
						: colums
				}
				dataSource={claimById?.electronicDocuments}
				pagination={{
					size: 'small',
					defaultPageSize: 25,
					pageSizeOptions: ['25', '50', '100'],
				}}
				onRow={(record, index) => {
					return {
						onClick: () => {
							setRowClassName(
								'ant-table-row ant-table-row-level-0 ant-table-row-selected'
							);
							setRowKey(record.id);
						},
					};
				}}
				rowClassName={(_record, index) => {
					return _record.id === rowKey ? rowClassName : '';
				}}
				rowKey={rowKey}
			/>

			<div
				style={{
					textAlign: 'end',
					color: 'black',
					marginRight: '5%',
				}}>
				<strong>
					Брой: {claimById?.electronicDocuments.length} Общо:{' '}
					{claimById?.totalAmount} / Общо(ново):{' '}
					{claimById?.payableAmount}{' '}
				</strong>
			</div>
			<Modal
				className='appointment-modal'
				title={'Преглед на електронен документ'}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				closeIcon={
					isAmbFormTouched ? (
						<Popconfirm
							title='Сигурни ли сте, че искате да затворите прозореца. Вашите промени няма да бъдат запазени!'
							onConfirm={async () =>
								await closePreviewAmbulatorySheet()
							}>
							<CloseOutlined />
						</Popconfirm>
					) : (
						<CloseOutlined onClick={closePreviewAmbulatorySheet} />
					)
				}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<AmbulatoryDocumentPreview
					closePreview={closePreviewAmbulatorySheet}
				/>
			</Modal>
		</div>
	);
};

export default observer(ClaimsByIdDocumentsModal);
