import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { useReactToPrint } from 'react-to-print';

import ModalMainInformation from './ModalMainInformation';
import ResolutionFormItem from './ResolutionFormItem';
import Messanger from '../../app/layout/Messanger/Messanger';

interface IProps {
	closeModal: () => Promise<void>;
	openModal: (id: number | undefined) => Promise<void>;
}

const ViewPriorApprovalModal: React.FC<IProps> = ({
	closeModal,
	openModal,
}) => {
	const rootStore = useContext(RootStoreContext);

	const { electronicDocumentById } = rootStore.electronicDocumentsStore;
	const { loadingModal } = rootStore.loadersStore;

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<Spin spinning={loadingModal}>
			<div>
				<ModalMainInformation
					electronicDocumentById={electronicDocumentById!}
				/>
				<ResolutionFormItem
					electronicDocumentById={electronicDocumentById!}
					handlePrint={handlePrint}
					componentRef={componentRef}
					closeModal={closeModal}
					openModal={openModal}
				/>
			</div>
			<Messanger />
		</Spin>
	);
};

export default observer(ViewPriorApprovalModal);
