import { Button, Modal, Popconfirm, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useRef, useState } from 'react';
import Moment from 'react-moment';
import constants from '../../app/constants/constants';
import { RootStoreContext } from '../../app/stores/rootStore';
import {
	FileSearchOutlined,
	CloseOutlined,
	PrinterOutlined,
	CloseCircleOutlined,
	CheckCircleOutlined,
	FileExcelOutlined,
} from '@ant-design/icons';
import AmbulatoryDocumentPreview from '../../app/layout/AmbulatoryDocumentPreview';
import { useReactToPrint } from 'react-to-print';
import PrintDetailsOnClaims from '../print/PrintDetailsOnClaims';

interface IProps {
	closeDetailsModal: () => Promise<void>;
}

const ClaimByIdDetailsModal: React.FC<IProps> = ({ closeDetailsModal }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		claimById,
		isAmbFormTouched,
		setIsAmbFormTouched,
		setIsPrintingClaimDetails,
	} = rootStore.claimStore;
	const {
		getElectronicDocumentById,
		exportClaimDetailsToExcel,
	} = rootStore.electronicDocumentsStore;

	const [showAmbulatorySheetModal, setShowAmbulatorySheetModal] = useState(
		false
	);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [rowKey, setRowKey] = useState('');

	const [expandRowKeys, setExpandRowKeys] = useState<any[]>([]);

	const previewAmbulatorySheet = async (id: number) => {
		if (id) {
			await getElectronicDocumentById(id);
			setShowAmbulatorySheetModal(true);
		}
	};

	const closePreviewAmbulatorySheet = async () => {
		await getElectronicDocumentById(undefined);
		setShowAmbulatorySheetModal(false);
		setIsAmbFormTouched(false);
	};

	const exportDetailsToExcel = async () => {
		await exportClaimDetailsToExcel(claimById?.id);

		if (
			rootStore.electronicDocumentsStore.claimDetailsExcelDownloadResult
		) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentsStore
						.claimDetailsExcelDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${claimById?.documentNumber}_procedures.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const data = claimById?.details;

	const columns = [
		{
			title: ' Дейност',
			width: '45%',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: ' Спец.',
			width: '5%',
			dataIndex: 'specialityCode',
			key: 'specialityCode',
			render: (_text: any, row: any, index: any) => (
				<div className='table-div-centered'>{row.specialityCode}</div>
			),
		},
		{
			title: ' Допълнителна информация',
			width: '20%',
			dataIndex: 'description',
			key: 'description',
			render: (_text: any, row: any, index: any) => (
				<div className='table-div-centered'>{row.description}</div>
			),
		},
		{
			title: ' Код ЗК',
			width: '5%',
			dataIndex: '',
			key: '',
			render: (_text: any, row: any, index: any) => (
				<div className='table-div-centered'>{/* TO DO */}</div>
			),
		},
		{
			title: 'Код по НЗОК',
			width: '5%',
			dataIndex: 'nhifCode',
			key: 'nhifCode',
			render: (_text: any, row: any, index: any) => (
				<div className='table-div-centered'>{row.nhifCode}</div>
			),
		},

		{
			title: 'Брой',
			width: '5%',
			dataIndex: 'count',
			key: 'count',
			render: (_text: any, row: any, index: any) => (
				<div className='table-div-centered'>{row.count}</div>
			),
		},
		{
			title: 'Ед.цена',
			width: '5%',
			dataIndex: 'price',
			key: 'price',
			render: (_text: any, row: any, index: any) => (
				<div style={{ float: 'right' }}>{row.price}</div>
			),
		},
		{
			title: 'Сума',
			width: '5%',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			render: (_text: any, row: any, index: any) => (
				<div style={{ float: 'right' }}>{row.totalAmount}</div>
			),
		},
	];

	const elDocColumns = [
		{
			title: '№',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			align: 'center' as 'center',
		},
		{
			title: 'От дата',
			dataIndex: '',
			key: '',
			align: 'center' as 'center',
			render: (text: any, document: any, index: any) => (
				<Moment
					format={
						document.documentType ===
						constants.DocumentType.AmbSheet ||
						document.documentType ===
						constants.DocumentType.DeseaseHistory
							? constants.Common.FullDate_Format_Without_Seconds
							: constants.Common.ShortDate_Format
					}>
					{document?.documentDate}
				</Moment>
			),
		},
		{
			title:claimById?.examinationType === '1' ? 'Вид' : undefined,
			dataIndex: claimById?.examinationType === '1' ? 'isPrimaryText' : '',
			key: claimById?.examinationType === '1' ? 'isPrimaryText' : '',
			align: 'center' as 'center',
		},
		{
			title: 'УИН',
			dataIndex: 'issuerUin',
			key: 'issuerUin',
			align: 'center' as 'center',
		},
		{
			title: 'Спец.',
			dataIndex: 'issuerSpecialityCode',
			key: 'issuerSpecialityCode',
			align: 'center' as 'center',
		},
		{
			title: 'ЕГН',
			dataIndex: 'patientUid',
			key: 'patientUid',
			align: 'center' as 'center',
		},
		{
			title: 'Име пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			align: 'center' as 'center',
		},
		{
			title: '№ карта',
			dataIndex: 'patientCard',
			key: 'patientCard',
			align: 'center' as 'center',
		},
		{
			title: 'МКБ код',
			dataIndex: 'mkbCode',
			key: 'mkbCode',
			align: 'center' as 'center',
		},
		{
			title: 'Сума',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			align: 'right' as 'right',
		},
		{
			title: 'МН',
			dataIndex: 'isMedicalReferralAttached',
			key: 'isMedicalReferralAttached',
			align: 'center' as 'center',
			render: (text: any, document: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'ИПО',
			dataIndex: 'isPriorApprovalAttached',
			key: 'isPriorApprovalAttached',
			align: 'center' as 'center',
			render: (text: any, document: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: claimById?.examinationType === '2' ? 'Лаб. рез.' : '',
			dataIndex:
				claimById?.examinationType === '2' ? 'isLabResultAttached' : '',
			key:
				claimById?.examinationType === '2' ? 'isLabResultAttached' : '',
			align: 'center' as 'center',
			width: claimById?.examinationType === '2' ? '0' : '',
			render: (text: any, document: any, index: any) =>
				claimById?.examinationType === '2' ? (
					<div
						style={{
							color: text ? 'green' : 'red',
							fontSize: 16,
						}}>
						{text ? (
							<CheckCircleOutlined />
						) : (
							<CloseCircleOutlined />
						)}
					</div>
				) : null,
		},
		{
			title: 'Отказ. дейн.',
			dataIndex: 'notPayableDetailsCount',
			key: 'notPayableDetailsCount',
			align: 'center' as 'center',
		},
		{
			title: 'Сума (нова)',
			dataIndex: 'payableAmount',
			key: 'payableAmount',
			align: 'right' as 'right',
		},
		{
			title: 'Преглед',
			dataIndex: 'id',
			key: 'id',
			align: 'center' as 'center',
			render: (tect: any, document: any, index: any) => (
				<FileSearchOutlined
					onClick={async () =>
						await previewAmbulatorySheet(document?.id)
					}
					style={{
						color: '#e48800',
						fontSize: 16,
						fontWeight: 700,
					}}
				/>
			),
		},
		{
			title: '',
			dataIndex: 'isForEditing',
			key: 'isForEditing',
			align: 'center' as 'center',
			render: (text: any, document: any, index: any) => (
				<div>{document?.isForEditing ? 'За кор.' : ' '}</div>
			),
		},
	];

	const elDocColumnsForPrevention = [
		{
			title: '№',
			dataIndex: 'documentNumber',
			key: 'documentNumber',
			align: 'center' as 'center',
		},
		{
			title: 'Дата',
			dataIndex: 'preventionDateFrom',
			key: 'preventionDateFrom',
			render: (row: any, record: any) => (
				<div className='table-div-centered'>
					<Moment format={constants.Common.ShortDate_Format}>
						{record.preventionDateFrom}
					</Moment>{' '}
					-{' '}
					<Moment format={constants.Common.ShortDate_Format}>
						{record.preventionDateTo}
					</Moment>
				</div>
			),
		},
		{
			title: 'ЕГН',
			dataIndex: 'patientUid',
			key: 'patientUid',
			align: 'center' as 'center',
		},
		{
			title: 'Име пациент',
			dataIndex: 'patientName',
			key: 'patientName',
			align: 'center' as 'center',
		},
		{
			title: '№ карта',
			dataIndex: 'patientCard',
			key: 'patientCard',
			align: 'center' as 'center',
		},
		{
			title: 'Сума',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			align: 'right' as 'right',
		},
		{
			title: 'Карта',
			dataIndex: 'isPreventionCardAttached',
			key: 'isPreventionCardAttached',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'Рез.',
			dataIndex: 'isLabResultAttached',
			key: 'isLabResultAttached',
			align: 'center' as 'center',
			render: (text: any, row: any, index: any) => (
				<div
					style={{
						color: text ? 'green' : 'red',
						fontSize: 16,
					}}>
					{text ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
				</div>
			),
		},
		{
			title: 'Отказ. дейн.',
			dataIndex: 'notPayableDetailsCount',
			key: 'notPayableDetailsCount',
			align: 'center' as 'center',
		},
		{
			title: 'Сума (нова)',
			dataIndex: 'payableAmount',
			key: 'payableAmount',
			align: 'right' as 'right',
		},
		{
			title: 'Преглед',
			dataIndex: 'id',
			key: 'id',
			align: 'center' as 'center',
			render: (tect: any, document: any, index: any) => (
				<FileSearchOutlined
					onClick={async () =>
						await previewAmbulatorySheet(document?.id)
					}
					style={{
						color: '#e48800',
						fontSize: 16,
						fontWeight: 700,
					}}
				/>
			),
		},
		{
			title: '',
			dataIndex: 'isForEditing',
			key: 'isForEditing',
			align: 'center' as 'center',
			render: (text: any, document: any, index: any) => (
				<div>{document?.isForEditing ? 'За кор.' : ' '}</div>
			),
		},
	];

	const content = (record: any) => (
		<Table
			className='elDocDetailsTable'
			dataSource={record?.electronicDocuments}
			columns={
				claimById?.examinationType === '3'
					? elDocColumnsForPrevention
					: elDocColumns
			}
			pagination={{
				size: 'small',
				defaultPageSize: 25,
				pageSizeOptions: ['25', '50', '100'],
			}}
		/>
	);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
	});

	return (
		<Fragment>
			<div>
				<div style={{ display: 'none' }}>
					<PrintDetailsOnClaims ref={componentRef} />
				</div>
				<Button
					key='print'
					type='primary'
					shape='round'
					style={{
						marginBottom: 5,
						borderRadius: 20,
						fontSize: 12,
					}}
					onClick={() => {
						setIsPrintingClaimDetails(true);
						setTimeout(() => {
							handlePrint();
						}, 2000);
					}}>
					<PrinterOutlined /> Принтирай
				</Button>
				<Button
					key='export'
					type='primary'
					shape='round'
					style={{
						marginBottom: 5,
						borderRadius: 20,
						marginLeft: 5,
						fontSize: 12,
					}}
					onClick={async () => await exportDetailsToExcel()}>
					<FileExcelOutlined /> Изтегляне (xlsx)
				</Button>
			</div>
			<div className='scroll-table-service-correlations'>
				<Table
					scroll={{ y: 'calc(100vh - 206px)' }}
					className='insurance-result-table'
					columns={columns}
					dataSource={data}
					pagination={false}
					rowKey='id'
					onRow={(record, index) => {
						return {
							onClick: () => {
								setRowClassName(
									'ant-table-row ant-table-row-level-0 ant-table-row-selected'
								);
								setRowKey(record.id.toString());
							},
						};
					}}
					rowClassName={(_record, index) => {
						return _record.id.toString() === rowKey
							? rowClassName
							: '';
					}}
					expandable={{
						expandedRowRender: (record) => (
							<div style={{ margin: 0 }}>{content(record)}</div>
						),
						onExpand: (expanded, record) => {
							if (
								expandRowKeys[0] !== undefined &&
								expandRowKeys[0] === record.id
							) {
								setExpandRowKeys([]);
							} else {
								setExpandRowKeys([record.id]);
								setRowClassName(
									'ant-table-row ant-table-row-level-0 ant-table-row-selected'
								);
								setRowKey(record.id.toString());
							}
						},
						expandedRowKeys: expandRowKeys,
						expandRowByClick: true,
					}}
				/>
			</div>
			<div
				style={{
					display: 'inline-flex',
					width: '100%',
					justifyContent: 'flex-end',
				}}>
				Брой резултати: {data?.length}{' '}
				<div style={{ margin: '0px 0px 0px 26px' }}>
					Сума:{' '}
					{data !== undefined && data.length !== 0
						? data
								?.map((a) => parseFloat(a.totalAmount ?? '0'))
								.reduce((a, b) => a + b)
								.toFixed(2)
						: 0.0}{' '}
				</div>
			</div>
			<Modal
				className='appointment-modal'
				title={'Преглед на електронен документ'}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				closeIcon={
					isAmbFormTouched ? (
						<Popconfirm
							title='Сигурни ли сте, че искате да затворите прозореца. Вашите промени няма да бъдат запазени!'
							onConfirm={async () =>
								await closePreviewAmbulatorySheet()
							}>
							<CloseOutlined />
						</Popconfirm>
					) : (
						<CloseOutlined
							onClick={async () =>
								await closePreviewAmbulatorySheet()
							}
						/>
					)
				}
				visible={showAmbulatorySheetModal}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<AmbulatoryDocumentPreview
					closePreview={closePreviewAmbulatorySheet}
				/>
			</Modal>
		</Fragment>
	);
};

export default observer(ClaimByIdDetailsModal);
