import React, { Fragment, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, ConfigProvider, List, Modal, Spin, Switch } from 'antd';
import { RootStoreContext } from '../../app/stores/rootStore';
import { INotification } from '../../app/models/notifications/Notification';
import Moment from 'react-moment';
import Constants from '../../app/constants/constants';
import { toJS } from 'mobx';
import Title from 'antd/lib/typography/Title';
import ViewPriorApprovalModal from '../PriorApproval/ViewPriorApprovalModal';
import {
	addUserMessage,
	renderCustomComponent,
	addResponseMessage,
	setBadgeCount,
	dropMessages,
} from 'react-chat-widget';
import CurrentDateChat from '../../app/layout/CustomComponents/CurrentDateChat';

const Notifications: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const {
		notReadNotifications,
		allNotifications,
		notificationHubConnection,
		markNotificationAsRead,
		markAllNotificationsAsRead,
		getAllNotifications,
		getNotReadNotifications,
		unreadNotificationsCount,
	} = rootStore.notificationStore;
	const {
		getElectronicDocumentById,
		electronicDocumentById,
	} = rootStore.electronicDocumentsStore;

	const {
		getAllMessages,
		removeMessages,
		addToMessangerHubGroup,
		markAsRead,
		removeFromMessangerHubGroup,
		clearTemporaryMessageQueue,
	} = rootStore.messangerStore;

	const {
		removeSelfNotificationForUnreadMessages,
	} = rootStore.notificationStore;

	const { loadingNotifications } = rootStore.loadersStore;

	const [show, setShow] = useState(false);
	const [isAllOpen, setIsAllOpen] = useState(false);

	const openPriorApprovalModal = async (id: number | undefined) => {
		if (id) {
			await getElectronicDocumentById(id);
			await getAllMessages(id);
			await addToMessangerHubGroup(id);

			rootStore.messangerStore.messages?.map((element) => {
				if (element.isInsuranceCompanyMessage) {
					addUserMessage(element.content);
					renderCustomComponent(CurrentDateChat, {
						date: element.createdOn,
						className: 'rcw-client',
					});
				} else {
					addResponseMessage(element.content);
					renderCustomComponent(CurrentDateChat, {
						date: element.createdOn,
						className: 'rcw-response',
					});
				}

				setBadgeCount(0);
			});
		}
		setShow(true);
	};

	const closePriorApprovalModal = async () => {
		setShow(false);
		await markAsRead(
			true,
			rootStore.electronicDocumentsStore.electronicDocumentById!.id
		);
		await removeSelfNotificationForUnreadMessages(
			parseInt(rootStore.identityStore.currentUser!.userId),
			rootStore.electronicDocumentsStore.electronicDocumentById!.id
		);

		dropMessages();
		removeMessages();
		clearTemporaryMessageQueue();

		await removeFromMessangerHubGroup(electronicDocumentById!.id);
	};

	const dateFormatted = (item: INotification) => {
		return (
			<div>
				<Moment format={Constants.Common.FullDate_Format}>
					{item.createdOn}
				</Moment>{' '}
				<div
					style={{
						display: 'inline-block',
						color: item.isRead ? 'inherit' : ' red',
						fontWeight: 'bold',
					}}>
					{' '}
					{item.isRead ? 'Прочетено' : ' Непрочетено'}{' '}
				</div>{' '}
			</div>
		);
	};

	const data = !isAllOpen
		? toJS(notReadNotifications)
		: toJS(allNotifications);

	useEffect(() => {
		notificationHubConnection?.on(
			'ChangedDocumentNotification',
			(res) => {}
		);
	}, [notReadNotifications, notificationHubConnection]);

	const title = (
		<div>
			ИПО № {electronicDocumentById?.documentNumber} от{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{electronicDocumentById?.createdOn}
			</Moment>
		</div>
	);

	const customizeRenderEmpty = () => (
		<div style={{ textAlign: 'center', fontSize: 20, color: 'black' }}>
			<p>Нямате нови непрочетени известия!</p>
		</div>
	);

	return (
		<Fragment>
			<Spin spinning={loadingNotifications}>
				<div className='home-grid-col-3'>
					<Title style={{ textAlign: 'center' }} level={3}>
						Известия
					</Title>
					<Button
						onClick={async () => {
							await markAllNotificationsAsRead();
							await getNotReadNotifications();
						}}
						disabled={unreadNotificationsCount === 0}
						className='btn-groupe'
						style={{
							background: '#234254',
							marginBottom: 20,
							marginRight: 20,
						}}
						type='primary'>
						Маркирай всички като прочетени
					</Button>
					<Switch
						className='switch-in-tables'
						defaultChecked={false}
						onChange={(value: any) => {
							value === false
								? getNotReadNotifications() &&
								  setIsAllOpen(false)
								: getAllNotifications() && setIsAllOpen(true);
						}}
						checkedChildren='Всички'
						unCheckedChildren='Непрочетени'></Switch>
					<ConfigProvider renderEmpty={customizeRenderEmpty}>
						<List
							pagination={{
								pageSize: 5,
							}}
							size='large'
							itemLayout='horizontal'
							dataSource={data}
							renderItem={(item) => (
								<List.Item>
									<List.Item.Meta
										title={dateFormatted(item)}
										description={item.content}
									/>
									<Button
										onClick={async () => {
											await markNotificationAsRead(
												item.id
											);
											await getNotReadNotifications();
										}}
										className='btn-groupe'
										type='primary'
										style={{
											display: item.isRead
												? 'none'
												: 'block',
											fontSize: 12,
											marginRight: 20,
											background: '#234254',
										}}>
										Маркирай като прочетено
									</Button>
									<Button
										onClick={async () => {
											await openPriorApprovalModal(
												item.documentId
											);
											await markNotificationAsRead(
												item.id
											);
											await getNotReadNotifications();
										}}
										className='btn-groupe'
										style={{
											background: '#234254',
										}}
										type='primary'>
										Преглед
									</Button>
								</List.Item>
							)}
						/>
					</ConfigProvider>
				</div>
				<Modal
					className='appointment-modal'
					title={title}
					centered
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					onCancel={() => closePriorApprovalModal()}
					visible={show}
					footer={false}
					destroyOnClose={true}>
					<ViewPriorApprovalModal
						openModal={openPriorApprovalModal}
						closeModal={closePriorApprovalModal}
					/>
				</Modal>
			</Spin>
		</Fragment>
	);
};

export default observer(Notifications);
