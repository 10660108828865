import { Space, Button, Table, Modal, Popconfirm, Input } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import { PlusOutlined, SyncOutlined, CloseOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { DateRangePicker } from 'rsuite';
import constants from '../../app/constants/constants';
import { IHelpdeskRequest } from '../../app/models/helpdesk/HelpdeskRequest';
import { RootStoreContext } from '../../app/stores/rootStore';
import HelpdeskModal from './HelpdeskModal';

const HelpdeskGrid: React.FC<TablePaginationConfig> = ({ total }) => {
	const [show, setShow] = useState(false);
	const [rowClassName, setRowClassName] = useState(
		'ant-table-row ant-table-row-level-0'
	);
	const [filterTable, setFilterTable] = useState([] as IHelpdeskRequest[]);
	const [rowKey, setRowKey] = useState();

	const rootStore = useContext(RootStoreContext);

	const {
		allHelpdeskRequests,
		setPeriod,
		getAllByPeriod,
		period,
		removeAllHelpdeskRequest,
		helpdeskById,
		getById,
		isFormTouched,
	} = rootStore.helpdeskStore;

	const { loadingAllTable } = rootStore.loadersStore;

	const data = allHelpdeskRequests;

	useEffect(() => {
		if (!allHelpdeskRequests) {
			getAllByPeriod(period);
		}

		return () => {
			removeAllHelpdeskRequest();
		};
	}, []);

	const changeDatesRange = (dates: Date[]) => {
		setPeriod(dates[0], dates[1]);
		setFilterTable([] as IHelpdeskRequest[]);
		getAllByPeriod(rootStore.helpdeskStore.period);
	};

	const compareByAlph = (a: any, b: any) => {
		if (a > b) {
			return -1;
		}
		if (a < b) {
			return 1;
		}
		return 0;
	};

	const openModal = async (helpdeskRequestId: number | undefined) => {
		if (helpdeskRequestId) {
			await getById(helpdeskRequestId);
		} else {
			await getById(undefined);
		}
		setShow(true);
	};

	const closeModal = async () => {
		setShow(false);
		await getById(undefined);
		await getAllByPeriod(period);
	};

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([] as IHelpdeskRequest[]);
		}
	};

	const columns = [
		{
			title: 'Заявка №',
			dataIndex: 'requestNumber',
			key: 'requestNumber',
			width: '10%',
			align: 'center' as 'center',
		},
		{
			title: 'Дата',
			dataIndex: 'createdOn',
			key: 'createdOn',
			width: '15%',
			align: 'center' as 'center',
			render: (_text: any, row: any, index: any) => (
				<Moment format={constants.Common.FullDate_Format}>
					{row.createdOn}
				</Moment>
			),
		},
		{
			title: 'Категория',
			dataIndex: 'categoryName',
			key: 'categoryName',
			width: '25%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.categoryName, b.categoryName);
			},
		},
		{
			title: 'Модул',
			dataIndex: 'moduleName',
			key: 'moduleName',
			width: '25%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.moduleName, b.moduleName);
			},
		},
		{
			title: 'Имейл за кореспонденция',
			dataIndex: 'contactEmail',
			key: 'contactEmail',
			width: '25%',
			align: 'center' as 'center',
			sorter: (a: any, b: any) => {
				return compareByAlph(a.contactEmail, b.contactEmail);
			},
		},
	];

	return (
		<Fragment>
			<div className='home-grid-col-3'>
				<Title level={3}>Последни заявки</Title>

				<Space className='space-table-head'>
					<Button
						className='btn-groupe'
						style={{
							background:
								'radial-gradient(circle, rgba(30,134,17,0.908000700280112) 28%, rgba(27,180,32,0.9248074229691877) 100%)',
						}}
						type='primary'
						icon={<PlusOutlined />}
						onClick={async () => await openModal(undefined!)}>
						Създаване
					</Button>
					<Button
						className='btn-groupe'
						style={{
							background: '#234254',
						}}
						type='primary'
						icon={<SyncOutlined />}
						onClick={() => getAllByPeriod(period)}>
						Опресняване
					</Button>
				</Space>
				<Space className='space-table-head'>
					<DateRangePicker
						placeholder={['дд.мм.гггг', 'дд.мм.гггг']}
						cleanable={false}
						className='date-range-tables'
						format={constants.Common.ShortDate_Format}
						value={[
							rootStore.helpdeskStore.period.dateFrom,
							rootStore.helpdeskStore.period.dateTo,
						]}
						onOk={(date: Date[]) => changeDatesRange(date)}
						locale={constants.ReactSuiteCalendarLocale}
						size='xs'
						color='green'
						ranges={[]}
						isoWeek={true}
					/>
					<Input.Search
						name='search'
						id='custom-search'
						className='search-input'
						placeholder='Търсене...'
						enterButton
						onSearch={search}
					/>
				</Space>

				<Table
					onRow={(record, index) => {
						return {
							onClick: () => {
								setRowClassName(
									'ant-table-row ant-table-row-level-0 ant-table-row-selected'
								);
								setRowKey(record.id);
							},
							onDoubleClick: async () => {
								await openModal(record.id);
							},
						};
					}}
					loading={loadingAllTable}
					columns={columns}
					dataSource={filterTable.length < 1 ? data : filterTable}
					locale={{ emptyText: 'Няма налични данни' }}
					sortDirections={['descend', 'ascend']}
					showSorterTooltip={false}
					pagination={{
						showSizeChanger: true,
						showQuickJumper: true,
						total: total,
						showTotal: (total) => `Брой резултати: ${total}`,
					}}
					rowClassName={(_record, index) => {
						return _record.id === rowKey ? rowClassName : '';
					}}
					rowKey={rowKey}
				/>

				<Modal
					key={helpdeskById?.id ? helpdeskById!.id : undefined}
					width='70%'
					title={
						helpdeskById
							? `Заявка №${helpdeskById?.requestNumber}`
							: 'Създаване на заявка'
					}
					centered
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					closeIcon={
						isFormTouched ? (
							<Popconfirm
								title='Сигурни ли сте, че искате да затворите прозореца. Вашите промени няма да бъдат запазени!'
								onConfirm={async () => await closeModal()}>
								<CloseOutlined />
							</Popconfirm>
						) : (
							<CloseOutlined onClick={closeModal} />
						)
					}
					visible={show}
					footer={false}
					destroyOnClose={true}>
					<HelpdeskModal
					// closeModal={closeModal}
					// openModal={openModal}
					/>
				</Modal>
			</div>
		</Fragment>
	);
};

export default observer(HelpdeskGrid);
