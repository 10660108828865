import React from 'react';
import Moment from 'react-moment';
import Constants from '../../constants/constants';

interface IProps {
	date: Date;
	className: string;
}

const CurrentDateChat: React.FC<IProps> = ({ date, className }) => {
	return (
		<span className={className}>
			<span className='rcw-timestamp'>
				<Moment format={Constants.Common.FullDate_Format}>
					{date}
				</Moment>
			</span>
		</span>
	);
};

export default CurrentDateChat;
