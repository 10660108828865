import React, { Fragment, useContext, useState } from 'react';
import { IElectronicDocument } from '../../app/models/electronicDocuments/ElectronicDocument';
import Constants from '../../app/constants/constants';
import { v4 as uuidv4 } from 'uuid';
import Moment from 'react-moment';
import { Modal } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { IDownloadAttachment } from '../../app/models/electronicDocuments/DownloadAttachment';
import { RootStoreContext } from '../../app/stores/rootStore';
import ElectonicDocumentPreveiw from '../../app/layout/ElectonicDocumentPreveiw';

interface IProps {
	electronicDocumentById: IElectronicDocument;
}

const ModalMainInformation: React.FC<IProps> = ({ electronicDocumentById }) => {
	const rootStore = useContext(RootStoreContext);
	const {
		downloadAttachment,
		getElectronicSubDocumentById,
	} = rootStore.electronicDocumentsStore;

	const [show, setShow] = useState(false);

	const download = async (
		patientUid: string,
		fileName: string,
		documentType: string
	) => {
		let model: IDownloadAttachment = {
			documentType: documentType,
			patientUid: patientUid,
			fileName: fileName,
		};

		await downloadAttachment(model);

		if (rootStore.electronicDocumentsStore.attachmentDownloadResult) {
			const url = window.URL.createObjectURL(
				new Blob([
					rootStore.electronicDocumentsStore
						.attachmentDownloadResult!,
				])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${model.documentType}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const preview = async (id: number) => {
		if (id) {
			await getElectronicSubDocumentById(id);
		}
		setShow(true);
	};

	return (
		<Fragment>
			<h3
				style={{
					display: electronicDocumentById?.documentStatus
						? 'inherit'
						: 'none',
				}}>
				Приоритет:{' '}
				<span
					style={{
						color:
							electronicDocumentById?.isImportant === false
								? 'inherit'
								: 'red',
					}}>
					{electronicDocumentById?.isImportant === false
						? 'Обикновен'
						: ' Спешен'}
				</span>
			</h3>
			<br />
			<h3
				style={{
					display: electronicDocumentById?.documentStatus
						? 'inherit'
						: 'none',
				}}>
				Актуален статус:{' '}
				<span
					style={{
						color:
							electronicDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Open
								? 'blue'
								: electronicDocumentById?.documentStatus ===
								  Constants.DocumentProcessStatus
										.OpenForModification
								? '#FF5722'
								: electronicDocumentById?.documentStatus ===
								  Constants.DocumentProcessStatus.Approved
								? 'green'
								: electronicDocumentById?.documentStatus ===
								  Constants.DocumentProcessStatus.Rejected
								? 'red'
								: 'inherit',
					}}>
					{electronicDocumentById?.documentStatus}
				</span>
			</h3>
			<br />
			<h3> Данни за лечебното заведение: </h3>
			<div className='view-referral-div'>
				ЛЗ: {electronicDocumentById?.medicalFacilityIssuerName}
			</div>
			<div className='view-referral-div'>
				Населено място:{' '}
				{electronicDocumentById?.medicalFacilityIssuerCity}
			</div>
			<br />

			<h3> Данни за пациента: </h3>
			<div className='view-referral-div'>
				ЕГН: {electronicDocumentById?.patientUid}
			</div>
			<div className='view-referral-div'>
				Име: {electronicDocumentById?.patientName}
			</div>
			<div className='view-referral-div'>
				Имейл: {electronicDocumentById?.pacientEmail}
			</div>

			<br />
			<h3> Данни за диагнозата: </h3>
			<div className='view-referral-div'>
				{electronicDocumentById?.mkbCode} -{' '}
				{electronicDocumentById?.mkbName}
			</div>
			<br />

			<h3> Назначени дейности: </h3>

			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 11,
					fontWeight: 600,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '10%' }}>
							Код по МКБ 9КМ
						</th>
						<th style={{ border: '1px solid ', width: '40%' }}>
							Дейност
						</th>
						<th style={{ border: '1px solid ', width: '20%' }}>
							Брой
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							Допълнителна информация
						</th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{electronicDocumentById?.details.map((detail) => (
						<tr key={uuidv4()}>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.nhifCode}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.name}
							</td>
							<td style={{ border: '1px solid ' }}>
								{detail.count}
							</td>
							<td style={{ border: '1px solid ' }}>
								{detail.description}
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<br />

			<span
				style={{
					display:
						electronicDocumentById?.attachments.length === 0
							? 'none'
							: 'inherit',
				}}>
				<h3> Прикачени файлове: </h3>

				<table
					style={{
						borderCollapse: 'collapse',
						textAlign: 'center',
						width: '100%',
						color: 'black',
						fontSize: 11,
					}}>
					<thead>
						<tr style={{ border: '1px solid ' }}>
							<th
								style={{
									border: '1px solid ',
									width: '2%',
								}}></th>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Тип на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Съдържание тип{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '13%',
								}}>
								{' '}
								Номер на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Дата на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								Допълнителна информация / Лекар
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								Преглед / Запазване
							</th>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid ' }}>
						{electronicDocumentById?.attachments.map(
							(attachment) => (
								<tr key={uuidv4()}>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										<div style={{ color: 'green' }}>
											{attachment.isNew
												? 'Нов'
												: undefined}
										</div>
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.attachmentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.documentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentNumber}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentDate !== null ? (
											<Moment
												format={
													Constants.Common
														.ShortDate_Format
												}>
												{attachment.eDocumentDate}
											</Moment>
										) : (
											''
										)}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.description
											? attachment.description
											: attachment.eDocumentDoctorName}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										<FileSearchOutlined
											onClick={async () =>
												attachment.attachmentType ===
												Constants.AttachmentType
													.PhysicalCopy
													? await download(
															electronicDocumentById!
																.patientUid,
															attachment.fileName,
															attachment.documentType
													  )
													: await preview(
															attachment!
																.toAttachmentId
													  )
											}
											style={{
												color: '#e48800',
												fontSize: 16,
												fontWeight: 700,
											}}
										/>
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
			</span>
			<br />
			<span
				style={{
					display: electronicDocumentById?.medicalNotes
						? 'inherit'
						: 'none',
				}}>
				<h3> Пояснения: </h3>

				<div style={{ fontSize: 18 }}>
					{electronicDocumentById?.medicalNotes}
				</div>
			</span>
			<br />
			<Modal
				className='appointment-modal'
				title={'Преглед на електронен документ'}
				centered
				maskClosable={false}
				transitionName='none'
				maskTransitionName='none'
				onCancel={() => setShow(false)}
				visible={show}
				footer={false}
				destroyOnClose={true}>
				{' '}
				<ElectonicDocumentPreveiw />
			</Modal>
		</Fragment>
	);
};

export default ModalMainInformation;
