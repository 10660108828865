import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import { v4 as uuidv4 } from 'uuid';

const PrintSubElDocumentFile: React.FC = () => {
	const rootStore = useContext(RootStoreContext);

	const { electronicSubDocumentById } = rootStore.electronicDocumentsStore;
	const { currentUser } = rootStore.identityStore;

	return (
		<div style={{ margin: '10px' }}>
			<h3
				style={{
					textAlign: 'center',
				}}>
				Застрахователна компания: {currentUser?.companyName} <br />
			</h3>

			<h3 style={{ textAlign: 'center' }}>
				{' '}
				{electronicSubDocumentById?.documentType}{' '}
				{electronicSubDocumentById?.documentNumber} от{' '}
				<Moment format={Constants.Common.FullDate_Format}>
					{electronicSubDocumentById?.createdOn}
				</Moment>
				г.
			</h3>
			<br />

			<h3
				style={{
					display: electronicSubDocumentById?.documentStatus
						? 'inherit'
						: 'none',
				}}>
				Приоритет:{' '}
				{electronicSubDocumentById?.isImportant === false
					? 'Обикновен'
					: ' Спешен'}
				<br />
			</h3>

			<h3
				style={{
					display: electronicSubDocumentById?.documentStatus
						? 'inherit'
						: 'none',
				}}>
				Актуален статус: {electronicSubDocumentById?.documentStatus}
				<br />
			</h3>
			<br />
			<span
				style={{
					display:
						electronicSubDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Approved ||
						electronicSubDocumentById?.documentStatus ===
							Constants.DocumentProcessStatus.Rejected
							? 'inherit'
							: 'none',
				}}>
				<h3> Резолюция: </h3>

				<div className='referral-print-div'>
					{electronicSubDocumentById?.insuranceCompanyNotes}
				</div>
				<br />
			</span>

			<span
				style={{
					display:
						electronicSubDocumentById?.documentType.toString() ===
						Constants.DocumentType.PriorApproval
							? 'inherit'
							: 'none',
				}}>
				<h3> Данни за лечебното заведение: </h3>
				<div className='referral-print-div'>
					ЛЗ: {electronicSubDocumentById?.medicalFacilityIssuerName}
				</div>
				<div className='referral-print-div'>
					Населено място:{' '}
					{electronicSubDocumentById?.medicalFacilityIssuerCity}
				</div>
				<br />
			</span>

			<span
				style={{
					display: electronicSubDocumentById?.insuranceCompanyName
						? 'inherit'
						: 'none',
				}}>
				<h4> До застрахователна компания: </h4>
				<div className='referral-print-div'>
					{electronicSubDocumentById?.insuranceCompanyName}
				</div>
				<br />
			</span>

			<h4> Данни за пациента: </h4>
			<div className='referral-print-div'>
				{' '}
				ЕГН: {electronicSubDocumentById?.patientUid}{' '}
			</div>
			<div className='referral-print-div'>
				{' '}
				Име: {electronicSubDocumentById?.patientName}
			</div>
			<br />

			<span
				style={{
					display: electronicSubDocumentById?.issuerUin
						? 'inherit'
						: 'none',
				}}>
				<h4> Данни за назначилия лекар: </h4>
				<div className='referral-print-div'>
					РЗИ/ЛЗ:{' '}
					{electronicSubDocumentById?.medicalFacilityIssuerRziCode}/{' '}
					{electronicSubDocumentById?.medicalFacilityIssuerName}/{' '}
					{electronicSubDocumentById?.medicalFacilityIssuerCity}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					УИН: {electronicSubDocumentById?.issuerUin}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					Име: {electronicSubDocumentById?.issuerName}{' '}
				</div>
				<div className='referral-print-div'>
					{' '}
					Специалност:{' '}
					{electronicSubDocumentById?.issuerSpecialityName}{' '}
				</div>
				<br />
			</span>

			<span
				style={{
					display:
						electronicSubDocumentById?.status ===
							Constants.MedicalReferralStatus.Performed ||
						electronicSubDocumentById?.performerUin
							? 'inherit'
							: 'none',
				}}>
				<h4>Данни за лекаря, запазил назначението:</h4>
				<div className='referral-print-div'>
					УИН: {electronicSubDocumentById?.performerUin}
				</div>
				<div className='referral-print-div'>
					Име: {electronicSubDocumentById?.performerName}
				</div>
				<div className='referral-print-div'>
					Специалност:{' '}
					{electronicSubDocumentById?.performerSpecialityName}
				</div>
				<br />
			</span>

			<span
				style={{
					display: electronicSubDocumentById?.insuranceCompanyName
						? 'inherit'
						: 'none',
				}}>
				<h4> Застрахователна компания: </h4>
				<div className='referral-print-div'>
					{electronicSubDocumentById?.insuranceCompanyName}
				</div>
				<br />
			</span>
			<h4> Данни за диагнозата: </h4>
			<div className='referral-print-div'>
				{electronicSubDocumentById?.mkbCode} -{' '}
				{electronicSubDocumentById?.mkbName}
			</div>
			<br />
			<span
				style={{
					display: electronicSubDocumentById?.toSpecialistSpecialityName
						? 'inherit'
						: 'none',
				}}>
				<h4> Специалност, към която се отнася: </h4>
				<div className='referral-print-div'>
					{electronicSubDocumentById?.toSpecialistSpecialityName}
				</div>
				<br />
			</span>

			<h4> Назначени дейности: </h4>

			<table
				style={{
					borderCollapse: 'collapse',
					textAlign: 'center',
					width: '100%',
					color: 'black',
					fontSize: 11,
				}}>
				<thead>
					<tr style={{ border: '1px solid ' }}>
						<th style={{ border: '1px solid ', width: '10%' }}>
							{' '}
							Код по МКБ 9КМ / НЗОК{' '}
						</th>
						<th style={{ border: '1px solid ', width: '40%' }}>
							{' '}
							Дейност{' '}
						</th>
						<th style={{ border: '1px solid ', width: '20%' }}>
							{' '}
							Брой
						</th>
						<th style={{ border: '1px solid ', width: '30%' }}>
							{' '}
							Допълнителна информация{' '}
						</th>
					</tr>
				</thead>
				<tbody style={{ border: '1px solid ' }}>
					{electronicSubDocumentById?.details.map((detail) => (
						<tr key={uuidv4()}>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'center',
								}}>
								{detail.nhifCode}
							</td>
							<td
								style={{
									border: '1px solid ',
									textAlign: 'left',
								}}>
								{detail.name}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.count}{' '}
							</td>
							<td style={{ border: '1px solid ' }}>
								{' '}
								{detail.description}{' '}
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<br />
			<span
				style={{
					display:
						electronicSubDocumentById?.attachments.length === 0
							? 'none'
							: 'inherit',
				}}>
				<h4> Прикачени файлове: </h4>

				<table
					style={{
						borderCollapse: 'collapse',
						textAlign: 'center',
						width: '100%',
						color: 'black',
						fontSize: 11,
					}}>
					<thead>
						<tr style={{ border: '1px solid ' }}>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Тип на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								{' '}
								Съдържание тип{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '10%',
								}}>
								{' '}
								Номер на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '15%',
								}}>
								{' '}
								Дата на документ{' '}
							</th>
							<th
								style={{
									border: '1px solid ',
									width: '30%',
								}}>
								Допълнителна информация / Лекар
							</th>
						</tr>
					</thead>
					<tbody style={{ border: '1px solid ' }}>
						{electronicSubDocumentById?.attachments.map(
							(attachment) => (
								<tr key={uuidv4()}>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.attachmentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.documentType}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentNumber}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{attachment.eDocumentDate !== null ? (
											<Moment
												format={
													Constants.Common
														.ShortDate_Format
												}>
												{attachment.eDocumentDate}
											</Moment>
										) : (
											''
										)}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{attachment.description
											? attachment.description
											: attachment.eDocumentDoctorName}
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
			</span>

			<br />

			<span
				style={{
					display: electronicSubDocumentById?.medicalNotes
						? 'inherit'
						: 'none',
				}}>
				<h4> Пояснения: </h4>

				<div style={{ border: '1px solid', paddingLeft: 10 }}>
					{electronicSubDocumentById?.medicalNotes}
				</div>
			</span>
		</div>
	);
};

export default observer(PrintSubElDocumentFile);
