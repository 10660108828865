import { Row, Col } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import PriorApprovalNextPendingButton from './PriorApprovalNextPendingButton';
import ProirApprovalsTable from './ProirApprovalsTable';

const PriorApproval: React.FC = () => {
	return (
		<Fragment>
			<Row gutter={[24, 24]}>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					xl={24}
					style={{ textAlignLast: 'center' }}>
					<div>
						<PriorApprovalNextPendingButton />
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<ProirApprovalsTable />
				</Col>
			</Row>
		</Fragment>
	);
};
export default observer(PriorApproval);
