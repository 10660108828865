import React, { useContext } from 'react';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import { observer } from 'mobx-react-lite';

const PrintDetailsSheet: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { claimById, isPrintingClaimDetails } = rootStore.claimStore;

	return (
		<div>
			{isPrintingClaimDetails ? (
				<div>
					<h3 style={{ textAlign: 'center' }}>
						{' '}
						Иск № {claimById?.documentNumber} за период{' '}
						<Moment format={Constants.Common.ShortDate_Format}>
							{claimById?.dateFrom}
						</Moment>{' '}
						-{' '}
						<Moment format={Constants.Common.ShortDate_Format}>
							{claimById?.dateTo}
						</Moment>{' '}
						за{'  '}
						{claimById?.examinationType === '1'
							? 'Амбулаторна дейност'
							: claimById?.examinationType === '2'
							? 'МДД'
							: claimById?.examinationType === '3'
							? 'Профилактична дейност'
							: ' Болнична дейност'}{' '}
						от "{claimById?.medicalFacilityName}" с РЗИ:{' '}
						{claimById?.rziCode} към "
						{claimById?.insuranceCompanyName}"
					</h3>
					<h3 style={{ textAlign: 'center' }}>
						Разбивка по извършени дейности
					</h3>
					<br />

					<table
						style={{
							borderCollapse: 'collapse',
							textAlign: 'center',
							width: '100%',
							color: 'black',
							fontSize: 11,
						}}>
						<thead>
							<tr style={{ border: '1px solid ' }}>
								<th
									style={{
										width: '40%',
										border: '1px solid ',
									}}>
									{' '}
									Дейност{' '}
								</th>
								<th
									style={{
										width: '5%',
										border: '1px solid ',
									}}>
									{' '}
									Спец.{' '}
								</th>
								<th
									style={{
										width: '20%',
										border: '1px solid ',
									}}>
									{' '}
									Допълнителна информация{' '}
								</th>
								<th
									style={{
										width: '10%',
										border: '1px solid ',
									}}>
									{' '}
									Код ЛЗ{' '}
								</th>
								<th
									style={{
										width: '10%',
										border: '1px solid ',
									}}>
									{' '}
									Код по МКБ 9КМ / НЗОК{' '}
								</th>

								<th
									style={{
										width: '5%',
										border: '1px solid ',
									}}>
									{' '}
									Брой
								</th>
								<th
									style={{
										width: '5%',
										border: '1px solid ',
									}}>
									{' '}
									Ед.цена{' '}
								</th>
								<th
									style={{
										width: '5%',
										border: '1px solid ',
									}}>
									{' '}
									Сума{' '}
								</th>
							</tr>
						</thead>
						<tbody
							style={{
								border: '1px solid ',
							}}>
							{claimById?.details.map((detail) => (
								<tr>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{' '}
										{detail?.name}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{' '}
										{detail?.specialityCode}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'left',
										}}>
										{' '}
										{detail?.description
											? detail?.description
											: ''}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{' '}
										{detail?.code}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{' '}
										{detail?.nhifCode}{' '}
									</td>

									<td
										style={{
											border: '1px solid ',
											textAlign: 'center',
										}}>
										{' '}
										{detail?.count}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'right',
										}}>
										{' '}
										{detail?.price}{' '}
									</td>
									<td
										style={{
											border: '1px solid ',
											textAlign: 'right',
										}}>
										{' '}
										{detail?.totalAmount}{' '}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div
						style={{
							textAlign: 'end',
							color: 'black',
						}}>
						Общо:{' '}
						{claimById?.details
							.map((detail) => parseFloat(detail.totalAmount))
							.reduce((a, b) => a + b)
							.toFixed(2)}{' '}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default observer(PrintDetailsSheet);
