import React, { Fragment, useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
	Input,
	Space,
	Button,
	Modal,
	DatePicker,
	Dropdown,
	Menu,
	Popover,
	Select,
	Spin,
	Empty,
} from 'antd';
import {
	SyncOutlined,
	DownOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import Moment from 'react-moment';
import { RootStoreContext } from '../../app/stores/rootStore';
import Constants from '../../app/constants/constants';
import moment from 'moment';
import { IClaim } from '../../app/models/claims/Claim';
import ClaimByIdDetailsModal from './ClaimByIdDetailsModal';
import ClaimsByIdDocumentsModal from './ClaimsByIdDocumentsModal';
import constants from '../../app/constants/constants';
import ReactDataGrid from 'react-data-grid';

const ClaimsTable: React.FC = () => {
	const [showDetails, setShowDetails] = useState(false);
	const [showDocuments, setShowDocuments] = useState(false);
	const [filterTable, setFilterTable] = useState([] as IClaim[]);
	const [isV, setisV] = useState(false);

	const rootStore = useContext(RootStoreContext);

	const {
		allClaims,
		getClaimsByPeriod,
		period,
		removeAllClaims,
		setPeriod,
		getClaimById,
		claimById,
		returnForEditing,
		perform,
		setAsProcessed,
		sortClaims,
		setFilterType,
		setIsPrintingClaimDoc,
		setIsPrintingClaimDetails,
	} = rootStore.claimStore;

	const { loadingAllTable } = rootStore.loadersStore;

	const handleWindowResize = () => {
		setisV(window.innerWidth < 1370);
	};

	const openDetailsModal = async (id: number) => {
		await getClaimById(id);
		setShowDetails(true);
	};

	const closeDetailsModal = async () => {
		setShowDetails(false);
		setIsPrintingClaimDoc(false);
		setIsPrintingClaimDetails(false);
		await getClaimById(undefined);
		await getClaimsByPeriod(rootStore.claimStore.period);
	};

	const openDocumentsModal = async (id: number) => {
		await getClaimById(id);
		setShowDocuments(true);
	};

	const closeDocumentsModal = async () => {
		setShowDocuments(false);
		setIsPrintingClaimDoc(false);
		setIsPrintingClaimDetails(false);
		await getClaimById(undefined);
		await getClaimsByPeriod(rootStore.claimStore.period);
	};

	useEffect(() => {
		if (!allClaims) {
			getClaimsByPeriod(period);
		}
		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
		return () => {
			removeAllClaims();
			setFilterType('all');
		};
	}, []);

	const data = allClaims!;

	const search = (value: any) => {
		if (value.length >= 1) {
			const tableFilterResult = data!.filter((o: any) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(value.toLowerCase())
				)
			);
			setFilterTable(tableFilterResult);
		} else if (value.length === 0) {
			setFilterTable([] as IClaim[]);
		}
	};

	const changeDatesRange = async (date: any) => {
		setPeriod(date.toDate());
		await getClaimsByPeriod(rootStore.claimStore.period);
	};

	const contentInfo = (row: any) => (
		<div>
			Последна редакция:{' '}
			<Moment format={Constants.Common.FullDate_Format}>
				{row.lastModifiedOn}
			</Moment>
			, от потребител: {row.lastModifiedByUsername}
		</div>
	);

	const menu = (row: IClaim) => (
		<Menu>
			<Menu.Item
				key={row.id + '2'}
				hidden={
					row.isPerformed ||
					row.status !== constants.ClaimProcessStatus.Processing
				}
				onClick={async () => {
					await perform(row.id);
					await getClaimsByPeriod(rootStore.claimStore.period);
				}}>
				<div>Вземане за обработка</div>
			</Menu.Item>
			<Menu.Item
				key={row.id + '0'}
				hidden={!row.isPerformed}
				onClick={async () => {
					await openDetailsModal(row.id);
				}}>
				<div>Разбивка по извършени дейности</div>
			</Menu.Item>
			<Menu.Item
				key={row.id + '1'}
				hidden={!row.isPerformed}
				onClick={async () => {
					await openDocumentsModal(row.id);
				}}>
				<div>Разбивка по документи</div>
			</Menu.Item>

			<Menu.Item
				key={row.id + '3'}
				hidden={
					!row.isPerformed ||
					(row?.status === Constants.ClaimProcessStatus.Processing &&
						row?.documentsForEditingCount === 0) ||
					row?.status !== Constants.ClaimProcessStatus.Processing
				}
				disabled={
					(row?.status === Constants.ClaimProcessStatus.Processing &&
						row?.documentsForEditingCount === 0) ||
					row?.status !== Constants.ClaimProcessStatus.Processing
				}>
				<div
					onClick={async () => {
						await returnForEditing(row.id);
						await getClaimsByPeriod(rootStore.claimStore.period);
					}}>
					Връщане за корекция
				</div>
			</Menu.Item>

			<Menu.Item
				key={row.id + '4'}
				hidden={
					!row.isPerformed ||
					(row?.status === Constants.ClaimProcessStatus.Processing &&
						row?.documentsForEditingCount > 0) ||
					row?.status !== Constants.ClaimProcessStatus.Processing
				}
				disabled={
					(row?.status === Constants.ClaimProcessStatus.Processing &&
						row?.documentsForEditingCount > 0) ||
					row?.status !== Constants.ClaimProcessStatus.Processing
				}>
				<div
					onClick={async () => {
						await setAsProcessed(row.id);
						await getClaimsByPeriod(rootStore.claimStore.period);
					}}>
					Приключване на иск
				</div>
			</Menu.Item>
		</Menu>
	);

	const column = [
		{
			name: ' №',
			width: 76,
			resizable: true,
			key: 'documentNumber',
			formatter: (row: any) => (
				<div className='table-div-centered'>
					{row.row.documentNumber}
				</div>
			),
		},
		{
			name: 'Депозиран на',
			width: isV ? 105 : null,
			resizable: true,
			key: 'dateProcessing',
			formatter: (row: any) => (
				<div className='table-div-centered'>
					<Moment
						format={
							Constants.Common.FullDate_Format_Without_Seconds
						}>
						{row.row.dateProcessing}
					</Moment>
				</div>
			),
		},
		{
			name: 'За период',
			width: isV ? 80 : 160,
			resizable: true,
			key: ' ',
			formatter: (row: any) => (
				<div className='table-div-centered'>
					<Moment format={Constants.Common.ShortDate_Format}>
						{row.row.dateFrom}
					</Moment>
					{' - '}
					<Moment format={Constants.Common.ShortDate_Format}>
						{row.row.dateTo}
					</Moment>
				</div>
			),
		},
		{
			name: 'Лечебно заведение',
			key: 'medicalFacilityName',
			width: isV ? 300 : 380,
			sortable: true,
			resizable: true,
			formatter: (row: any) => (
				<div className='table-div-centered'>
					{row.row.medicalFacilityName}
				</div>
			),
		},
		{
			name: 'Насел. място',
			key: 'medicalFacilityCity',
			width: isV ? 105 : null,
			sortable: true,
			formatter: (row: any) => (
				<div className='table-div-centered'>
					{row.row.medicalFacilityCity}
				</div>
			),
		},
		{
			name: 'Тип дейност',
			key: 'examinationType',
			width: isV ? 94 : null,
			sortable: true,
			formatter: (row: any) => (
				<div className='table-div-centered'>
					{row.row.examinationType === '1'
						? 'Амбулаторна'
						: row.row.examinationType === '2'
						? 'МДД'
						: row.row.examinationType === '3'
						? 'Профилактична'
						: 'Болнична'}
				</div>
			),
		},
		{
			name: 'Бр.док.',
			key: 'totalAttachedDocuments',
			width: 58,
			sortable: true,
			formatter: (row: any) => (
				<div className='table-div-centered'>
					{row.row.totalAttachedDocuments}
				</div>
			),
		},
		{
			name: 'Искана сума',
			key: 'totalAmount',
			width: isV ? 70 : null,
			sortable: true,
			formatter: (row: any) => (
				<div style={{ textAlignLast: 'right' }}>
					{row.row.totalAmount}
				</div>
			),
		},
		{
			name: 'Крайна сума',
			key: 'payableAmount',
			width: isV ? 70 : null,
			sortable: true,
			formatter: (row: any) => (
				<div style={{ textAlignLast: 'right' }}>
					{row.row.payableAmount}
				</div>
			),
		},
		{
			name: 'Статус',
			key: 'status',
			width: 82,
			sortable: true,
			formatter: (row: any) => (
				<div className='table-div-centered'>
					{row.row.isPerformed &&
					row.row.status === constants.ClaimProcessStatus.Processing
						? `${row.row.status} Взет`
						: row.row.status}
				</div>
			),
		},
		{
			name: '',
			key: 'id',
			width: 90,
			sortable: true,
			formatter: (row: any) => (
				<Dropdown
					overlay={menu(row.row)}
					key={row.row.id}
					trigger={['click']}>
					<div
						className='table-div-centered'
						onClick={(e) => {
							e.preventDefault();
						}}>
						Действия <DownOutlined />
					</div>
				</Dropdown>
			),
		},
		{
			name: '',
			width: 23,
			key: '',
			sortable: true,
			formatter: (row: any) => (
				<div className='table-div-centered'>
					<Popover
						content={contentInfo(row.row)}
						trigger='hover'
						placement='topRight'
						style={{ fontSize: 18 }}>
						<InfoCircleOutlined />
					</Popover>
				</div>
			),
		},
	];

	const title = (
		<div>
			{' '}
			Иск № {claimById?.documentNumber} за период{' '}
			<Moment format={Constants.Common.ShortDate_Format}>
				{claimById?.dateFrom}
			</Moment>{' '}
			-{' '}
			<Moment format={Constants.Common.ShortDate_Format}>
				{claimById?.dateTo}
			</Moment>{' '}
			за{'  '}
			{claimById?.examinationType === '1'
				? 'Амбулаторна дейност'
				: claimById?.examinationType === '2'
				? 'МДД'
				: claimById?.examinationType === '3'
				? 'Профилактична дейност'
				: 'Болнична дейност'}{' '}
			от "{claimById?.medicalFacilityName}"{' '}
			{claimById?.medicalFacilityCity} с РЗИ: {claimById?.rziCode}
		</div>
	);

	const { Option } = Select;

	const sortRows = (initialRows, sortColumn, sortDirection) => {
		const comparer = (a, b) => {
			if (sortDirection === 'ASC') {
				return a[sortColumn] > b[sortColumn] ? 1 : -1;
			} else if (sortDirection === 'DESC') {
				return a[sortColumn] < b[sortColumn] ? 1 : -1;
			}
		};
		sortClaims(comparer, sortDirection);
	};

	const markSelectedRow = (rowIndex: number, row: IClaim) => {
		if (row !== undefined) {
			var allSelected = document.querySelectorAll(
				'.react-grid-Cell-selected'
			);
			allSelected.forEach((el) =>
				el.classList.remove('react-grid-Cell-selected')
			);

			let targetRowToSelect = document.querySelector(
				'[value="' + row.id + '"]'
			).parentElement;
			let childrenArray = Array.from(
				targetRowToSelect.children as HTMLCollectionOf<HTMLElement>
			);

			childrenArray.forEach((element) => {
				element.classList.add('react-grid-Cell-selected');
			});
		}
	};

	return (
		<Fragment>
			<div className='home-grid-col-3'>
				<Title level={3}>Искове</Title>

				<Space className='space-table-head'>
					<Button
						className='btn-groupe'
						style={{
							background: '#234254',
						}}
						type='primary'
						icon={<SyncOutlined />}
						onClick={() => getClaimsByPeriod(period)}>
						Опресняване
					</Button>
					<Select
						className='claims-table-select'
						defaultValue='all'
						style={{ width: 150 }}
						bordered={false}
						onSelect={async (value) => {
							setFilterType(value);
							await getClaimsByPeriod(period);
						}}>
						<Option value='all'>Всички</Option>
						<Option value='processing'>Депозирани</Option>
						<Option value='taken'>Взети</Option>
						<Option value='editable'>За корекция</Option>
						<Option value='performed'>Обработени</Option>
					</Select>
				</Space>
				<Space className='space-table-head' style={{ bottom: '-2px' }}>
					<DatePicker
						onChange={(date) => changeDatesRange(date)}
						className='date-range-tables'
						picker='month'
						format={'MMMM - YYYY'}
						allowClear={false}
						defaultValue={moment().subtract(1, 'months')}
						disabledDate={(current) =>
							current > moment().endOf('month')
						}
					/>
					<Input.Search
						className='search-input'
						placeholder='Търсене...'
						enterButton
						onSearch={search}
					/>
				</Space>

				<Spin size='large' spinning={loadingAllTable}>
					<div
						className='scroll-table-service-correlations ins-claim-table-grid '
						style={{
							maxHeight: 'calc(100vh - 205px)',
							overflowY: 'hidden',
						}}>
						{allClaims !== undefined ? (
							<ReactDataGrid
								rowHeight={isV ? 50 : 45}
								columns={column}
								rowGetter={(i) =>
									filterTable.length < 1
										? data[i]
										: filterTable[i]
								}
								rowsCount={
									filterTable.length < 1
										? data.length
										: filterTable.length
								}
								onRowClick={(rowIndex: number, row: IClaim) =>
									markSelectedRow(rowIndex, row)
								}
								emptyRowsView={() => <Empty />}
								minHeight={1000}
								onGridSort={(sortColumn, sortDirection) => {
									return sortRows(
										filterTable.length < 1
											? data
											: filterTable,
										sortColumn,
										sortDirection
									);
								}}
							/>
						) : null}
					</div>
				</Spin>
				<div
					style={{
						display: 'inline-flex',
						paddingRight: '54px',
						fontSize: 16,
						color: ' black',
						width: '100%',
						justifyContent: ' flex-end',
					}}>
					Брой резултати:{' '}
					{filterTable === undefined ||
					(filterTable !== undefined && filterTable!.length === 0)
						? data?.length
						: filterTable!.length}{' '}
					<div style={{ margin: '0px 0px 0px 26px' }}>
						Сума:{' '}
						{(filterTable === undefined ||
							(filterTable !== undefined &&
								filterTable!.length === 0)) &&
						data !== undefined &&
						data.length !== 0
							? data
									?.map((a) =>
										parseFloat(a.totalAmount ?? '0')
									)
									.reduce((a, b) => a + b)
									.toFixed(2)
							: filterTable.length >= 1
							? filterTable
									?.map((a) =>
										parseFloat(a.totalAmount ?? '0')
									)
									.reduce((a, b) => a + b)
									.toFixed(2)
							: 0.0}{' '}
						/ Сума(платима):{' '}
						{(filterTable === undefined ||
							(filterTable !== undefined &&
								filterTable!.length === 0)) &&
						data !== undefined &&
						data.length !== 0
							? data
									?.map((a) =>
										parseFloat(
											a.payableAmount ?? a.totalAmount
										)
									)
									.reduce((a, b) => a + b)
									.toFixed(2)
							: filterTable.length >= 1
							? filterTable
									?.map((a) =>
										parseFloat(
											a.payableAmount ?? a.totalAmount
										)
									)
									.reduce((a, b) => a + b)
									.toFixed(2)
							: 0.0}{' '}
					</div>
				</div>
				<Modal
					width='100%'
					title={title}
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					centered
					onCancel={async () => await closeDetailsModal()}
					visible={showDetails}
					footer={false}
					destroyOnClose={true}>
					<ClaimByIdDetailsModal
						closeDetailsModal={async () =>
							await closeDetailsModal()
						}
					/>
				</Modal>

				<Modal
					width='100%'
					title={title}
					maskClosable={false}
					transitionName='none'
					maskTransitionName='none'
					centered
					onCancel={async () => await closeDocumentsModal()}
					visible={showDocuments}
					footer={false}
					destroyOnClose={true}>
					<ClaimsByIdDocumentsModal />
				</Modal>
			</div>
		</Fragment>
	);
};

export default observer(ClaimsTable);
