import React from 'react';
import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

export interface IProps {
	tip: any;
}

const LoadingComponent: React.FC<IProps> = ({ tip }) => {
	return (
		<div className='loader-placeholder'>
			<Spin
				size='large'
				style={{ fontSize: 25, fontWeight: 'bold' }}
				indicator={<LoadingOutlined style={{ fontSize: 25 }} spin />}
				tip={tip}
			/>
		</div>
	);
};

export default observer(LoadingComponent);
